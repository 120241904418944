import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    container: {
        marginBottom: '1em'
    },
    therapistDisplay: {
        color: 'black',
        fontWeight: 'bold'
    }

}))

export default styles
