export const fittingData = {
    'Emma D XD': [
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        }
    ],
    'Emma Office': [
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        }
    ],
    'Emma SOVS': [
        {
            maxExtraWidth: 10,
            size: 20.0
        },
        {
            maxExtraWidth: 10,
            size: 21.0
        },
        {
            maxExtraWidth: 10,
            size: 22.0
        },
        {
            maxExtraWidth: 10,
            size: 23.0
        },
        {
            maxExtraWidth: 10,
            size: 24.0
        },
        {
            maxExtraWidth: 10,
            size: 25.0
        },
        {
            maxExtraWidth: 10,
            size: 26.0
        },
        {
            maxExtraWidth: 10,
            size: 27.0
        },
        {
            maxExtraWidth: 10,
            size: 28.0
        },
        {
            maxExtraWidth: 10,
            size: 29.0
        },
        {
            maxExtraWidth: 10,
            size: 30.0
        },
        {
            maxExtraWidth: 10,
            size: 31.0
        },
        {
            maxExtraWidth: 10,
            size: 32.0
        },
        {
            maxExtraWidth: 10,
            size: 33.0
        },
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ],
    'Emma XXD': [
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        }
    ],
    'Finn Comfort': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        }
    ],
    Hartjes: [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        }
    ],
    Huisschoen: [
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        }
    ],
    'PLT 3D': [
        {
            maxExtraWidth: 10,
            size: 20.0
        },
        {
            maxExtraWidth: 10,
            size: 21.0
        },
        {
            maxExtraWidth: 10,
            size: 22.0
        },
        {
            maxExtraWidth: 10,
            size: 23.0
        },
        {
            maxExtraWidth: 10,
            size: 24.0
        },
        {
            maxExtraWidth: 10,
            size: 25.0
        },
        {
            maxExtraWidth: 10,
            size: 26.0
        },
        {
            maxExtraWidth: 10,
            size: 27.0
        },
        {
            maxExtraWidth: 10,
            size: 28.0
        },
        {
            maxExtraWidth: 10,
            size: 29.0
        },
        {
            maxExtraWidth: 10,
            size: 30.0
        },
        {
            maxExtraWidth: 10,
            size: 31.0
        },
        {
            maxExtraWidth: 10,
            size: 32.0
        },
        {
            maxExtraWidth: 10,
            size: 33.0
        },
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ],
    'PLT Elegant': [
        {
            maxExtraWidth: 10,
            size: 20.0
        },
        {
            maxExtraWidth: 10,
            size: 21.0
        },
        {
            maxExtraWidth: 10,
            size: 22.0
        },
        {
            maxExtraWidth: 10,
            size: 23.0
        },
        {
            maxExtraWidth: 10,
            size: 24.0
        },
        {
            maxExtraWidth: 10,
            size: 25.0
        },
        {
            maxExtraWidth: 10,
            size: 26.0
        },
        {
            maxExtraWidth: 10,
            size: 27.0
        },
        {
            maxExtraWidth: 10,
            size: 28.0
        },
        {
            maxExtraWidth: 10,
            size: 29.0
        },
        {
            maxExtraWidth: 10,
            size: 30.0
        },
        {
            maxExtraWidth: 10,
            size: 31.0
        },
        {
            maxExtraWidth: 10,
            size: 32.0
        },
        {
            maxExtraWidth: 10,
            size: 33.0
        },
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ],
    'PLT Kids': [
        {
            maxExtraWidth: 10,
            size: 20.0
        },
        {
            maxExtraWidth: 10,
            size: 21.0
        },
        {
            maxExtraWidth: 10,
            size: 22.0
        },
        {
            maxExtraWidth: 10,
            size: 23.0
        },
        {
            maxExtraWidth: 10,
            size: 24.0
        },
        {
            maxExtraWidth: 10,
            size: 25.0
        },
        {
            maxExtraWidth: 10,
            size: 26.0
        },
        {
            maxExtraWidth: 10,
            size: 27.0
        },
        {
            maxExtraWidth: 10,
            size: 28.0
        },
        {
            maxExtraWidth: 10,
            size: 29.0
        },
        {
            maxExtraWidth: 10,
            size: 30.0
        },
        {
            maxExtraWidth: 10,
            size: 31.0
        },
        {
            maxExtraWidth: 10,
            size: 32.0
        },
        {
            maxExtraWidth: 10,
            size: 33.0
        },
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        }
    ],
    'PLT Mens Dress': [
        {
            maxExtraWidth: 3,
            size: 36.0
        },
        {
            maxExtraWidth: 3,
            size: 37.0
        },
        {
            maxExtraWidth: 3,
            size: 38.0
        },
        {
            maxExtraWidth: 3,
            size: 39.0
        },
        {
            maxExtraWidth: 3,
            size: 40.0
        },
        {
            maxExtraWidth: 3,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ],
    'PLT Slim': [
        {
            maxExtraWidth: 10,
            size: 20.0
        },
        {
            maxExtraWidth: 10,
            size: 21.0
        },
        {
            maxExtraWidth: 10,
            size: 22.0
        },
        {
            maxExtraWidth: 10,
            size: 23.0
        },
        {
            maxExtraWidth: 10,
            size: 24.0
        },
        {
            maxExtraWidth: 10,
            size: 25.0
        },
        {
            maxExtraWidth: 10,
            size: 26.0
        },
        {
            maxExtraWidth: 10,
            size: 27.0
        },
        {
            maxExtraWidth: 10,
            size: 28.0
        },
        {
            maxExtraWidth: 10,
            size: 29.0
        },
        {
            maxExtraWidth: 10,
            size: 30.0
        },
        {
            maxExtraWidth: 10,
            size: 31.0
        },
        {
            maxExtraWidth: 10,
            size: 32.0
        },
        {
            maxExtraWidth: 10,
            size: 33.0
        },
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ],
    'PLT Small Sport': [
        {
            maxExtraWidth: 10,
            size: 20.0
        },
        {
            maxExtraWidth: 10,
            size: 21.0
        },
        {
            maxExtraWidth: 10,
            size: 22.0
        },
        {
            maxExtraWidth: 10,
            size: 23.0
        },
        {
            maxExtraWidth: 10,
            size: 24.0
        },
        {
            maxExtraWidth: 10,
            size: 25.0
        },
        {
            maxExtraWidth: 10,
            size: 26.0
        },
        {
            maxExtraWidth: 10,
            size: 27.0
        },
        {
            maxExtraWidth: 10,
            size: 28.0
        },
        {
            maxExtraWidth: 10,
            size: 29.0
        },
        {
            maxExtraWidth: 10,
            size: 30.0
        },
        {
            maxExtraWidth: 10,
            size: 31.0
        },
        {
            maxExtraWidth: 10,
            size: 32.0
        },
        {
            maxExtraWidth: 10,
            size: 33.0
        },
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ],
    'PLT Sport': [
        {
            maxExtraWidth: 10,
            size: 20.0
        },
        {
            maxExtraWidth: 10,
            size: 21.0
        },
        {
            maxExtraWidth: 10,
            size: 22.0
        },
        {
            maxExtraWidth: 10,
            size: 23.0
        },
        {
            maxExtraWidth: 10,
            size: 24.0
        },
        {
            maxExtraWidth: 10,
            size: 25.0
        },
        {
            maxExtraWidth: 10,
            size: 26.0
        },
        {
            maxExtraWidth: 10,
            size: 27.0
        },
        {
            maxExtraWidth: 10,
            size: 28.0
        },
        {
            maxExtraWidth: 10,
            size: 29.0
        },
        {
            maxExtraWidth: 10,
            size: 30.0
        },
        {
            maxExtraWidth: 10,
            size: 31.0
        },
        {
            maxExtraWidth: 10,
            size: 32.0
        },
        {
            maxExtraWidth: 10,
            size: 33.0
        },
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ],
    'VM - Durea 174': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Durea 178': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Durea 314': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Durea 318': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Durea 814': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Durea 818': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Durea Gijs H404': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Durea Gijs H408': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Durea Gijs H704': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Durea Gijs H708': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Hartjes Diab': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        }
    ],
    'VM - Meindl Comfort Fit': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Neskrid 443': [
        {
            maxExtraWidth: 10,
            size: 0.0
        },
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        },
        {
            maxExtraWidth: 10,
            size: 17.0
        },
        {
            maxExtraWidth: 10,
            size: 18.0
        },
        {
            maxExtraWidth: 10,
            size: 19.0
        },
        {
            maxExtraWidth: 10,
            size: 20.0
        },
        {
            maxExtraWidth: 10,
            size: 21.0
        },
        {
            maxExtraWidth: 10,
            size: 22.0
        },
        {
            maxExtraWidth: 10,
            size: 23.0
        },
        {
            maxExtraWidth: 10,
            size: 24.0
        },
        {
            maxExtraWidth: 10,
            size: 25.0
        },
        {
            maxExtraWidth: 10,
            size: 26.0
        },
        {
            maxExtraWidth: 10,
            size: 27.0
        },
        {
            maxExtraWidth: 10,
            size: 28.0
        },
        {
            maxExtraWidth: 10,
            size: 29.0
        },
        {
            maxExtraWidth: 10,
            size: 30.0
        },
        {
            maxExtraWidth: 10,
            size: 31.0
        },
        {
            maxExtraWidth: 10,
            size: 32.0
        },
        {
            maxExtraWidth: 10,
            size: 33.0
        },
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ],
    'VM - Neskrid 522': [
        {
            maxExtraWidth: 10,
            size: 0.0
        },
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        },
        {
            maxExtraWidth: 10,
            size: 17.0
        },
        {
            maxExtraWidth: 10,
            size: 18.0
        },
        {
            maxExtraWidth: 10,
            size: 19.0
        },
        {
            maxExtraWidth: 10,
            size: 20.0
        },
        {
            maxExtraWidth: 10,
            size: 21.0
        },
        {
            maxExtraWidth: 10,
            size: 22.0
        },
        {
            maxExtraWidth: 10,
            size: 23.0
        },
        {
            maxExtraWidth: 10,
            size: 24.0
        },
        {
            maxExtraWidth: 10,
            size: 25.0
        },
        {
            maxExtraWidth: 10,
            size: 26.0
        },
        {
            maxExtraWidth: 10,
            size: 27.0
        },
        {
            maxExtraWidth: 10,
            size: 28.0
        },
        {
            maxExtraWidth: 10,
            size: 29.0
        },
        {
            maxExtraWidth: 10,
            size: 30.0
        },
        {
            maxExtraWidth: 10,
            size: 31.0
        },
        {
            maxExtraWidth: 10,
            size: 32.0
        },
        {
            maxExtraWidth: 10,
            size: 33.0
        },
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ],
    'VM - Nimco 39K': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Nimco 39M': [
        {
            maxExtraWidth: 10,
            size: 1.0
        },
        {
            maxExtraWidth: 10,
            size: 1.5
        },
        {
            maxExtraWidth: 10,
            size: 2.0
        },
        {
            maxExtraWidth: 10,
            size: 2.5
        },
        {
            maxExtraWidth: 10,
            size: 3.0
        },
        {
            maxExtraWidth: 10,
            size: 3.5
        },
        {
            maxExtraWidth: 10,
            size: 4.0
        },
        {
            maxExtraWidth: 10,
            size: 4.5
        },
        {
            maxExtraWidth: 10,
            size: 5.0
        },
        {
            maxExtraWidth: 10,
            size: 5.5
        },
        {
            maxExtraWidth: 10,
            size: 6.0
        },
        {
            maxExtraWidth: 10,
            size: 6.5
        },
        {
            maxExtraWidth: 10,
            size: 7.0
        },
        {
            maxExtraWidth: 10,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - Nimco 39O': [
        {
            maxExtraWidth: 0,
            size: 1.0
        },
        {
            maxExtraWidth: 0,
            size: 1.5
        },
        {
            maxExtraWidth: 0,
            size: 2.0
        },
        {
            maxExtraWidth: 0,
            size: 2.5
        },
        {
            maxExtraWidth: 0,
            size: 3.0
        },
        {
            maxExtraWidth: 0,
            size: 3.5
        },
        {
            maxExtraWidth: 0,
            size: 4.0
        },
        {
            maxExtraWidth: 0,
            size: 4.5
        },
        {
            maxExtraWidth: 0,
            size: 5.0
        },
        {
            maxExtraWidth: 0,
            size: 5.5
        },
        {
            maxExtraWidth: 0,
            size: 6.0
        },
        {
            maxExtraWidth: 0,
            size: 6.5
        },
        {
            maxExtraWidth: 0,
            size: 7.0
        },
        {
            maxExtraWidth: 0,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - PLT Mens Dress': [
        {
            maxExtraWidth: 3,
            size: 36.0
        },
        {
            maxExtraWidth: 3,
            size: 37.0
        },
        {
            maxExtraWidth: 3,
            size: 38.0
        },
        {
            maxExtraWidth: 3,
            size: 39.0
        },
        {
            maxExtraWidth: 3,
            size: 40.0
        },
        {
            maxExtraWidth: 3,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ],
    'VM - TOM W10': [
        {
            maxExtraWidth: 0,
            size: 1.0
        },
        {
            maxExtraWidth: 0,
            size: 1.5
        },
        {
            maxExtraWidth: 0,
            size: 2.0
        },
        {
            maxExtraWidth: 0,
            size: 2.5
        },
        {
            maxExtraWidth: 0,
            size: 3.0
        },
        {
            maxExtraWidth: 0,
            size: 3.5
        },
        {
            maxExtraWidth: 0,
            size: 4.0
        },
        {
            maxExtraWidth: 0,
            size: 4.5
        },
        {
            maxExtraWidth: 0,
            size: 5.0
        },
        {
            maxExtraWidth: 0,
            size: 5.5
        },
        {
            maxExtraWidth: 0,
            size: 6.0
        },
        {
            maxExtraWidth: 0,
            size: 6.5
        },
        {
            maxExtraWidth: 0,
            size: 7.0
        },
        {
            maxExtraWidth: 0,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - TOM W11': [
        {
            maxExtraWidth: 0,
            size: 1.0
        },
        {
            maxExtraWidth: 0,
            size: 1.5
        },
        {
            maxExtraWidth: 0,
            size: 2.0
        },
        {
            maxExtraWidth: 0,
            size: 2.5
        },
        {
            maxExtraWidth: 0,
            size: 3.0
        },
        {
            maxExtraWidth: 0,
            size: 3.5
        },
        {
            maxExtraWidth: 0,
            size: 4.0
        },
        {
            maxExtraWidth: 0,
            size: 4.5
        },
        {
            maxExtraWidth: 0,
            size: 5.0
        },
        {
            maxExtraWidth: 0,
            size: 5.5
        },
        {
            maxExtraWidth: 0,
            size: 6.0
        },
        {
            maxExtraWidth: 0,
            size: 6.5
        },
        {
            maxExtraWidth: 0,
            size: 7.0
        },
        {
            maxExtraWidth: 0,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    'VM - TOM W12': [
        {
            maxExtraWidth: 0,
            size: 1.0
        },
        {
            maxExtraWidth: 0,
            size: 1.5
        },
        {
            maxExtraWidth: 0,
            size: 2.0
        },
        {
            maxExtraWidth: 0,
            size: 2.5
        },
        {
            maxExtraWidth: 0,
            size: 3.0
        },
        {
            maxExtraWidth: 0,
            size: 3.5
        },
        {
            maxExtraWidth: 0,
            size: 4.0
        },
        {
            maxExtraWidth: 0,
            size: 4.5
        },
        {
            maxExtraWidth: 0,
            size: 5.0
        },
        {
            maxExtraWidth: 0,
            size: 5.5
        },
        {
            maxExtraWidth: 0,
            size: 6.0
        },
        {
            maxExtraWidth: 0,
            size: 6.5
        },
        {
            maxExtraWidth: 0,
            size: 7.0
        },
        {
            maxExtraWidth: 0,
            size: 7.5
        },
        {
            maxExtraWidth: 10,
            size: 8.0
        },
        {
            maxExtraWidth: 10,
            size: 8.5
        },
        {
            maxExtraWidth: 10,
            size: 9.0
        },
        {
            maxExtraWidth: 10,
            size: 9.5
        },
        {
            maxExtraWidth: 10,
            size: 10.0
        },
        {
            maxExtraWidth: 10,
            size: 10.5
        },
        {
            maxExtraWidth: 10,
            size: 11.0
        },
        {
            maxExtraWidth: 10,
            size: 11.5
        },
        {
            maxExtraWidth: 10,
            size: 12.0
        },
        {
            maxExtraWidth: 10,
            size: 12.5
        },
        {
            maxExtraWidth: 10,
            size: 13.0
        },
        {
            maxExtraWidth: 10,
            size: 13.5
        },
        {
            maxExtraWidth: 10,
            size: 14.0
        },
        {
            maxExtraWidth: 10,
            size: 14.5
        },
        {
            maxExtraWidth: 10,
            size: 15.0
        },
        {
            maxExtraWidth: 10,
            size: 15.5
        },
        {
            maxExtraWidth: 10,
            size: 16.0
        }
    ],
    Voetbal: [
        {
            maxExtraWidth: 10,
            size: 20.0
        },
        {
            maxExtraWidth: 10,
            size: 21.0
        },
        {
            maxExtraWidth: 10,
            size: 22.0
        },
        {
            maxExtraWidth: 10,
            size: 23.0
        },
        {
            maxExtraWidth: 10,
            size: 24.0
        },
        {
            maxExtraWidth: 10,
            size: 25.0
        },
        {
            maxExtraWidth: 10,
            size: 26.0
        },
        {
            maxExtraWidth: 10,
            size: 27.0
        },
        {
            maxExtraWidth: 10,
            size: 28.0
        },
        {
            maxExtraWidth: 10,
            size: 29.0
        },
        {
            maxExtraWidth: 10,
            size: 30.0
        },
        {
            maxExtraWidth: 10,
            size: 31.0
        },
        {
            maxExtraWidth: 10,
            size: 32.0
        },
        {
            maxExtraWidth: 10,
            size: 33.0
        },
        {
            maxExtraWidth: 10,
            size: 34.0
        },
        {
            maxExtraWidth: 10,
            size: 35.0
        },
        {
            maxExtraWidth: 10,
            size: 36.0
        },
        {
            maxExtraWidth: 10,
            size: 37.0
        },
        {
            maxExtraWidth: 10,
            size: 38.0
        },
        {
            maxExtraWidth: 10,
            size: 39.0
        },
        {
            maxExtraWidth: 10,
            size: 40.0
        },
        {
            maxExtraWidth: 10,
            size: 41.0
        },
        {
            maxExtraWidth: 10,
            size: 42.0
        },
        {
            maxExtraWidth: 10,
            size: 43.0
        },
        {
            maxExtraWidth: 10,
            size: 44.0
        },
        {
            maxExtraWidth: 10,
            size: 45.0
        },
        {
            maxExtraWidth: 10,
            size: 46.0
        },
        {
            maxExtraWidth: 10,
            size: 47.0
        },
        {
            maxExtraWidth: 10,
            size: 48.0
        },
        {
            maxExtraWidth: 10,
            size: 49.0
        },
        {
            maxExtraWidth: 10,
            size: 50.0
        }
    ]
}
