import { getPrefix } from './util'

/**
 * Turns the sole additions into a single readable string
 */
function generateAdditions (add, sole) {
    const addition = []
    add.forEach(add => {
        if (add.forSoles.includes(sole)) {
            switch (add.location) {
            case 'left':
                addition.push(add.name + ' - Links')
                break
            case 'right':
                addition.push(add.name + ' - Rechts')
                break
            case 'both':
                addition.push(add.name + ' - Links en Rechts')
                break
            default:
                break
            }
        }
    })
    addition.toString()
    return addition
}

/**
 * Performs a deep copy on the object.
 * This preserves any non-object types
 *
 * @param {Object} state the object to copy
 * @param {String} filename the name of the file
 * @param {Number} sole current sole.
 * @returns {String} a CSV file.
 */
function generateCSV (state, filename, sole, researchDownload = false) {
    const orderType = state.general.orderType === 'fits'
    const coverLevel = state.soleSpecification[sole].soleData.coverLevel
    const coverLevelLeft = coverLevel.includes('Volledig Afdekken') || coverLevel.includes('Links Afdekken') ? 'Volledig Afdekken' : 'Los bijleveren'
    const coverLevelRight = coverLevel.includes('Volledig Afdekken') || coverLevel.includes('Rechts Afdekken') ? 'Volledig Afdekken' : 'Los bijleveren'

    const blockSole = generateBlock(state.soleSpecification[sole].soleData.block.key)
    const blockFits = generateBlock(state.fitsSpecification[sole].fitsData.block)

    const patientNumber = `${getPrefix(state.other.backend)}${state.general.patientData.number}`

    const soleRows = [
        ['Patientnummer:', '', '', patientNumber, '', '', ''],
        ['Patientnaam:', '', '', state.general.patientData.lastName, '', ''],
        ['', '', '', '', '', '', ''],
        ['Stap 1 - Specificaties', '', '', '', '', ''],
        ['Selecteer je freesbestand:', '', '', filename, '', '', ''],
        ['', '', '', '', '', '', ''],
        ['', '', '', 'Links', '', '', 'Rechts'],
        ['Freesblok', '', '', '', '', '', ''],
        ['Hoogte freesblok', '', '', state.soleSpecification[sole].soleData.millingSize, '', '', state.soleSpecification[sole].soleData.millingSize],
        ['Type freesblok', '', '', blockSole.name, '', '', blockSole.name],
        ['Freeskwaliteit', '', '', 'Fijn', '', '', 'Fijn'],
        ['', '', '', '', '', '', ''],
        ['Afdekmateriaal', '', '', '', '', '', ''],
        ['Afdekniveau', '', '', coverLevelLeft, '', '', coverLevelRight],
        ['Afdekmateriaal', '', '', state.soleSpecification[sole].soleData.coverMaterial, '', '', state.soleSpecification[sole].soleData.coverMaterial],
        ['', '', '', '', '', '', ''],
        ['Afwerkingsvorm', '', '', '', '', '', ''],
        ['Afwerkingsvorm', '', '', 'Slijpen', '', '', 'Slijpen'],
        // Hier evt. nog extra elementen toevoegen
        ['Type zool', '', '', state.soleSpecification[sole].soleData.soleCompleteness, '', '', state.soleSpecification[sole].soleData.soleCompleteness],
        ['Grondzoolpatroon', '', '', state.soleSpecification[sole].soleData.groundSole.name, '', '', state.soleSpecification[sole].soleData.groundSole.name],
        ['Maat', '', '', state.soleSpecification[sole].soleData.soleSizeLeft, '', '', state.soleSpecification[sole].soleData.soleSizeRight],
        ['', '', '', '', '', '', ''],
        ['Opmerkingen', '', '', state.general.patientData.therapist.name + (state.soleResult[sole].soleResultData.left.fasciPad === 'true' ? ' - Fasci opvulling Links' : '') + (state.soleResult[sole].soleResultData.right.fasciPad === 'true' ? ' - Fasci opvulling Rechts' : '') + (state.soleResult[sole].soleResultData.left.ascPad === 'true' ? ' - Ascendens opvulling links' : '') + (state.soleResult[sole].soleResultData.right.ascPad === 'true' ? ' - Ascendens opvulling rechts' : '') + (state.soleAddition.additionData.narrowGrinding === true ? ' - Zolen getailleerd slijpen' : ''), '', '', '' + generateAdditions(state.soleAddition.additionData.addition, sole), '', '', ''],
        ['', '', '', '', '', '', ''],
        ['', '', '', 'Klik op volgende', '', '', ''],
        ['', '', '', '', '', '', ''],
        ['Stap 2 - Logistiek & besteloverzicht', '', '', '', '', ''],
        ['Verzenddatum', '', '', state.general.patientData.emergencyOrder ? 'SPOED: Pas de datum aan naar de eerst mogelijke datum.' : 'De datum hoeft niet aangepast te worden.', '', '', ''],
        ['Aanmeetlocatie:', '', '', state.general.patientData.fittingLocation.title, '', '', ''],
        ['Ontvangstlocatie:', '', '', state.general.patientData.receiveLocation.title, '', '', ''],
        ['', '', '', '', '', '', ''],
        ['', '', '', 'Klik op plaats bestelling', '', '', '']
    ]

    const fitsRows = [
        ['Patientnummer:', '', '', state.general.patientData.number, '', '', ''],
        ['Patientnaam:', '', '', state.general.patientData.lastName, '', '', ''],
        ['', '', '', '', '', '', ''],
        ['Stap 1 - Specificaties', '', '', '', ''],
        ['Selecteer je bestand(en):', '', '', filename, '', '', ''],
        ['', '', '', '', '', '', ''],
        ['', '', '', 'Links', '', '', 'Rechts'],
        ['Model', '', '', '', '', '', ''],
        ['Geslacht', '', '', state.general.patientData.gender === 'male' ? 'Heren' : 'Dames', '', '', state.general.patientData.gender === 'male' ? 'Heren' : 'Dames'],
        ['Type model', '', '', state.fitsSpecification[sole].fitsData.model.displayValue, '', '', state.fitsSpecification[sole].fitsData.model.displayValue],
        ['Materiaal', '', '', state.fitsSpecification[sole].fitsData.upperMaterial, '', '', state.fitsSpecification[sole].fitsData.upperMaterial],
        ['', '', '', '', '', '', ''],
        ['Freesblok', '', '', '', '', '', ''],
        ['Hoogte freesblok', '', '', blockFits.height, '', '', blockFits.height], // hoogte toevoegen, 30/32(?) of 38mm
        ['Type freesblok', '', '', blockFits.name, '', '', blockFits.name],
        ['', '', '', '', '', '', ''],
        ['Afdekmateriaal', '', '', state.fitsSpecification[sole].fitsData.coverMaterial, '', '', state.fitsSpecification[sole].fitsData.coverMaterial],
        ['Afdekniveau', '', '', state.fitsSpecification[sole].fitsData.finishingShape, '', '', state.fitsSpecification[sole].fitsData.finishingShape],
        ['', '', '', '', '', '', ''],
        ['Loopzool', '', '', '', '', '', ''],
        // Hier evt. nog extra elementen toevoegen
        // ['Element toevoegen:', '', state.fitsSpecification[sole].fitsData.extraElement.includes('30sh 3mm EVA - zwart') ? '30sh 3mm EVA - zwart' : 'Geen'] ???
        ['Type', '', '', 'Zool afgewerkt', '', '', 'Zool afgewerkt'],
        ['Kleur', '', '', state.fitsSpecification[sole].fitsData.outsole, '', '', state.fitsSpecification[sole].fitsData.outsole], // zie vorige opmerking
        ['', '', '', '', '', '', ''],
        ['Aanmeetplank maat', '', '', state.fitsSpecification[sole].fitsData.soleSizeLeft, '', '', state.fitsSpecification[sole].fitsData.soleSizeRight],
        ['Wijdtemaat (mm):', '', '', state.fitsSpecification[sole].fitsData.leftSoleWidth, '', '', state.fitsSpecification[sole].fitsData.rightSoleWidth],
        ['Wijdtemaat advies', '', state.fitsSpecification[sole].fitsData.leftSoleWidthMessage, '', '', state.fitsSpecification[sole].fitsData.rightSoleWidthMessage],
        ['Interne hielheffing', '', '', '', '', '', ''],
        ['Externe hielheffing', '', '', state.soleResult[sole].soleResultData.left.externalHeelLift, '', '', state.soleResult[sole].soleResultData.right.externalHeelLift],
        ['', '', '', '', '', '', ''],
        ['Opmerkingen:', '', state.general.patientData.therapist.name + (state.soleResult[0].soleResultData.left.fasciPad === 'true' ? ' - Fasci opvulling Links' : '') + (state.soleResult[0].soleResultData.right.fasciPad === 'true' ? ' - Fasci opvulling Rechts' : '') + (state.soleResult[0].soleResultData.left.ascPad === 'true' ? ' - Ascendens opvulling Links' : '') + (state.soleResult[0].soleResultData.right.ascPad === 'true' ? ' - Ascendens opvulling Rechts' : ''), ''],
        ['', '', '', '', '', '', ''],
        ['', '', '', 'Klik op volgende', '', '', ''],
        ['', '', '', '', '', '', ''],
        ['Stap 2 - Logistiek & besteloverzicht', '', '', '', '', ''],
        ['Verzenddatum', '', '', 'De datum hoeft niet aangepast te worden.', '', '', ''],
        ['Aanmeetlocatie:', '', '', state.general.patientData.fittingLocation.title, '', '', ''],
        ['Ontvangstlocatie:', '', '', state.general.patientData.receiveLocation.title, '', '', ''],
        ['', '', '', '', '', '', ''],
        ['', '', '', 'Klik op plaats bestelling', '', '', '']
    ]

    let csvContent = researchDownload ? 'data:text/csv;charset=utf-8,' : ''

    orderType
        ? fitsRows.forEach(function (rowArray) {
            const row = rowArray.join(';')
            csvContent += row + '\r\n'
        })
        : soleRows.forEach(function (rowArray) {
            const row = rowArray.join(';')
            csvContent += row + '\r\n'
        })
    return csvContent
}

/**
 * Generate a block object with a string
 *
 * @param {String} block - block name
 * @return {object} block object with height and name
 */
function generateBlock (block) {
    const objBlock = {}
    if (block.includes('38mm hoog')) {
        objBlock.height = '38 mm'
        objBlock.name = block.replace(' - 38mm hoog', '')
    } else if (block.includes(' - 38mm -')) {
        objBlock.height = '38 mm'
        objBlock.name = block.replace(' - 38mm', '')
    } else if (block.includes(' - 30mm -')) {
        objBlock.height = '30 mm'
        objBlock.name = block.replace(' - 30mm', '')
    } else {
        objBlock.height = '30 mm'
        objBlock.name = block
    }

    return objBlock
}

export { generateCSV }
