import ExcelJS from 'exceljs'
import moment from 'moment'

/**
 * Get a full state export
 *
 * @param {Object} state - full state.
 * @returns {Object} full export for Podo-IT
 */
async function excelExport (state, soleNr, name) {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook()

    // Add a worksheet to the workbook
    const worksheet = workbook.addWorksheet('bestelformulier')

    // Loop through the array and add the data to the worksheet
    excelExportArray(state, soleNr).forEach((row) => {
        worksheet.addRow(row)
    })

    // Generate a buffer from the workbook
    return workbook.xlsx.writeBuffer()
        .then((buffer) => {
            // Create a Blob from the buffer
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            // Create a download link
            const downloadLink = document.createElement('a')
            downloadLink.href = URL.createObjectURL(blob)
            downloadLink.download = name

            // Trigger the download
            downloadLink.click()

            // Clean up the temporary URL object
            URL.revokeObjectURL(downloadLink.href)
        })
        .catch((error) => {
            console.error('Error creating Excel file:', error)
        })
}

/**
 *
 * @param {*} state
 * @param {*} soleNr
 * @returns
 */
function excelExportArray (state, soleNr) {
    const receiveLocation = state.general.patientData.receiveLocation.id === -1 ? 'via Kievit Hengelo versturen' : state.general.patientData.receiveLocation.name

    const csv = [
        ['Bestelformulier 3D-printzolen PLT', '', ''],
        ['Praktijknaam', 'Voetencentrum Wender', ''],
        ['Gebruiker', `${state.general.patientData.therapist.name}`, ''],
        ['Patiëntnummer', `${state.general.patientData.number}`, ''],
        ['Datum van insturen', moment().format('YYYY-MM-DD'), ''],
        ['Verzenddag', '', ''],
        ['', '', ''],
        ['', 'Links', 'Rechts'],
        ['Voetmaat', parseInt(state.soleSpecification[soleNr].soleData.footSizeLeft), parseInt(state.soleSpecification[soleNr].soleData.footSizeRight)],
        ['Hoogte voetboog', getValueSoleResult(state, 'MIC', soleNr, 'left'), getValueSoleResult(state, 'MIC', soleNr, 'right')],
        ['', '', ''],
        ['Afdeksoort', `${state.soleSpecification[soleNr].soleData.coverMaterial}`, ''],
        ['Afdekniveau', `${state.soleSpecification[soleNr].soleData.coverLevel}`, ''],
        ['Verbreed voorblad', `${state.soleSpecification[soleNr].soleData.broadenedFront}`, ''],
        ['', '', ''],
        ['Extra opties', '', ''],
        ['Pelotte', getValueSoleResult(state, 'pelotte', soleNr, 'left'), getValueSoleResult(state, 'pelotte', soleNr, 'right')],
        ['Ascendens', getValueSoleResult(state, 'ASC', soleNr, 'left'), getValueSoleResult(state, 'ASC', soleNr, 'right')],
        ['', '', ''],
        ['Opmerkingen (max 500 tekens):', `${state.diagnosis.diagnosisData.symmetrical ? '' : 'niet symmetrische schoenen'}`, ''],
        ['', '', ''],
        ['Zoolmaat', parseInt(state.soleSpecification[soleNr].soleData.soleSizeLeft), parseInt(state.soleSpecification[soleNr].soleData.soleSizeRight)],
        ['SA Element', getValueSoleResult(state, 'SA15', soleNr, 'left'), getValueSoleResult(state, 'SA15', soleNr, 'right')],
        ['PPSI', getValueSoleResult(state, 'PPSI', soleNr, 'left'), getValueSoleResult(state, 'PPSI', soleNr, 'right')],
        ['SPSI', getValueSoleResult(state, 'SPSI', soleNr, 'left'), getValueSoleResult(state, 'SPSI', soleNr, 'right')],
        ['Aanmeetlocatie', `${state.general.patientData.fittingLocation.name}`, ''],
        ['Ontvangstlocatie', `${receiveLocation}`, ''],
        ['', '', '']
    ]

    return csv
}

/**
 *
 * @param {*} key
 * @param {*} soleNr
 */
function getValueSoleResult (state, key, soleNr, side) {
    // if there is not an value entered check for an suggestion.
    if (state.soleResult[soleNr].soleResultData[side][key] === '' || state.soleResult[soleNr].soleResultData[side][key] === null) {
        // check the suggestion value
        const suggestionValue = state.soleResult[soleNr].soleResultSuggestion[side][key]
        if (suggestionValue !== '') {
            // not empty, use it
            return suggestionValue || 0
        }
    } else {
        return state.soleResult[soleNr].soleResultData[side][key]
    }
}

export { excelExport, excelExportArray }
