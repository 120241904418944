import React, { useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Tooltip,
    Typography,
    Fab,
    Button,
    Box,
    Checkbox,
    Autocomplete
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { ExpandMore, Add } from '@mui/icons-material'
import useStyles from './style'

import { useSelector, useDispatch } from 'react-redux'
import { soleData, soleAccordion } from '../../store/reducers/soleSpecification'
import { tafelberg, validateAscFasci } from '../../store/reducers/soleResult'
import dataProvider from '../../lib/dataProvider'
import { deepCopy, euToUk, ukToEu } from '../../lib/util'

const SoleSpecifications = ({ classes, soleSpecNumber }) => {
    // state management
    const dispatch = useDispatch()
    const orderType = useSelector(state => state.general.orderType)
    const sole = useSelector((state) => state.soleSpecification[soleSpecNumber].soleData)
    const importSuggestion = useSelector((state) => state.importSuggestion.soleSpecification[soleSpecNumber])
    const error = useSelector((state) => state.soleSpecification[soleSpecNumber].soleErrors)
    const tab = useSelector((state) => state.soleSpecification[soleSpecNumber].soleAccordion)
    const diagnosisTab = useSelector((state) => state.diagnosis.diagnosisAccordion)
    const emergencyOrder = useSelector((state) => state.general.patientData.emergencyOrder)
    const diagnosis = useSelector((state) => state.diagnosis.diagnosisData)
    const sole0 = useSelector((state) => state.soleSpecification[0].soleData)
    const soles = useSelector((state) => state.soleSpecification)
    const addition = useSelector((state) => state.soleAddition)
    const { soleResultData, soleResultSuggestion, soleResultErrors } = useSelector((state) => state.soleResult[soleSpecNumber])

    const [smallestSize, setSmallestSize] = useState('')
    const [broadenedFrontText, setBroadenedFrontText] = useState('1-10mm')
    /**
     * Handlers , the open and close of the accordion
     */
    function toggleAccordion () {
        dispatch(soleAccordion({ key: 'accordion', value: !tab.accordion, soleSpecNumber }))
    }

    /**
     * Update the mill that is selected
     * @param {Object} millSize value of the mill selected
     */
    function onMillSelected (millSize) {
        dispatch(soleData({ key: 'millingSize', value: millSize, soleSpecNumber, diagnosis }))

        const temp = deepCopy(soles)
        temp[soleSpecNumber].soleData.millingSize = millSize
        dispatch(tafelberg({ diagnosis, sole: temp, soleResNumber: soleSpecNumber, side: 'left' }))
        dispatch(tafelberg({ diagnosis, sole: temp, soleResNumber: soleSpecNumber, side: 'right' }))
    }

    /**
     * Set the selected block
     *
     * @param {String} newInputValue The selected Block
     */
    function onBlockSelected (newInputValue) {
        if (newInputValue === undefined || newInputValue === null) return

        dispatch(soleData({ key: 'block', value: !newInputValue ? '' : newInputValue, soleSpecNumber, emergencyOrder, diagnosis }))

        onMillSelected(newInputValue.millingSize)

        const temp = deepCopy(soles)
        temp[soleSpecNumber].soleData.block = newInputValue
        temp[soleSpecNumber].soleData.millingSize = newInputValue.millingSize
        onLeftSoleThickness(newInputValue.soleThickness, temp)
        onRightSoleThickness(newInputValue.soleThickness, temp)

        let newGS = sole.groundSole
        soleSizeMinMax(newInputValue, newGS)
    }

    /**
     * Check minmax sizes of groundsoles.
     *
     * @param {String} block - selected block
     * @param {Object} groundsole - selected groundsole
     */
    function soleSizeMinMax (block, groundsole) {
        const newGround = dataProvider.soleSpecification.groundSolePatternTypes.find(gs => (gs.name === groundsole.name))
        dispatch(soleData({ key: 'groundSole', value: newGround, soleSpecNumber }))
    }

    /**
     * update foot size. At the moment it considers a number like 5 higher than 43. Cause yet unknown.
     *
     * @param {String} size Size to set the sole size to.
     * @param {Boolean} rightSize true if right side is filled.
     */
    function onFootSizeInput (size, rightSize = false) {
        size = `${size}`.replace(',', '.')
        size = isNaN(parseFloat(size)) ? '' : parseFloat(size)
        let biggestSize
        if (!rightSize) {
            if (sole.footSizeRight === '' || sole.footSizeRight === sole.footSizeLeft) {
                dispatch(soleData({ key: 'footSizeRight', value: size, soleSpecNumber }))
            }
            if (sole.groundSole.sizeSystem === 'UK') onSoleSizeInput(euToUk(size))
            else if ((sole.soleSizeLeft === '' || sole.soleSizeLeft === sole.footSizeLeft || (size > sole.footSizeRight && sole.footSizeRight === sole.soleSizeLeft))) {
                onSoleSizeInput(size)
            }
            dispatch(soleData({ key: 'footSizeLeft', value: size, soleSpecNumber }))
        } else {
            const soleSizeLeft = `${sole.groundSole.sizeSystem === 'UK' ? ukToEu(sole.soleSizeLeft) : sole.soleSizeLeft}`
            if (sole.soleSizeLeft === '' || soleSizeLeft === sole.footSizeRight || soleSizeLeft === sole.footSizeLeft) {
                dispatch(soleData({ key: 'footSizeRight', value: size, soleSpecNumber }))
                biggestSize = sole.footSizeLeft > size ? sole.footSizeLeft : size

                // left and right are the same here
                const maxWidth = sole.groundSole.fittingData.find(item => parseInt(item.size) === parseInt(size))
                if (maxWidth !== undefined) {
                    setSmallestSize(size)
                    setBroadenedFrontText(`0-${maxWidth.maxExtraWidth}mm`)
                }

                // CHECK EU/UK SIZE
                if (sole.groundSole.sizeSystem === 'UK') onSoleSizeInput(euToUk(biggestSize))
                else onSoleSizeInput(biggestSize)
            } else {
                dispatch(soleData({ key: 'footSizeRight', value: size, soleSpecNumber }))
            }
        }

        if (soleResultSuggestion.left.pelotte !== '' && soleResultSuggestion.right.pelotte !== '') {
            if (rightSize) {
                biggestSize = sole.footSizeRight > size ? sole.footSizeRight : size
            } else {
                biggestSize = sole.footSizeLeft > size ? sole.footSizeLeft : size
            }
        }
    }

    /**
     * update sole size
     *
     * @param {String} size Size to set the sole size to.
     * @param {Boolean} rightSize true if right side is filled.
     */
    function onSoleSizeInput (size, rightSize = false) {
        size = `${size}`.replace(',', '.')
        size = size === '' || size.endsWith(".") ? size : isNaN(size = parseFloat(size)) ? '' : size;
        if (!rightSize) {
            if (sole.soleSizeRight === '' || sole.soleSizeRight === sole.soleSizeLeft) {
                dispatch(soleData({ key: 'soleSizeRight', value: size, soleSpecNumber }))
            }
            dispatch(soleData({ key: 'soleSizeLeft', value: size, soleSpecNumber }))

            // left and right are the same here
            const maxWidth = sole.groundSole.fittingData.find(item => parseFloat(item.size) === parseFloat(size))
            if (maxWidth !== undefined) {
                setSmallestSize(size)
                setBroadenedFrontText(`0-${maxWidth.maxExtraWidth}mm`)
            }
            return
        } else {
            dispatch(soleData({ key: 'soleSizeRight', value: size, soleSpecNumber }))
        }

        if (rightSize) {
            const smSize = size < sole.soleSizeLeft ? size : sole.soleSizeLeft
            const maxWidth = sole.groundSole.fittingData.find(item => parseFloat(item.size) === parseFloat(size))

            if (maxWidth !== undefined) {
                setSmallestSize(smSize)
                setBroadenedFrontText(`0-${maxWidth.maxExtraWidth}mm`)
            }
        } else {
            const smSize = size < sole.soleSizeRight ? size : sole.soleSizeRight
            const maxWidth = sole.groundSole.fittingData.find(item => parseFloat(item.size) === parseFloat(smSize))

            if (maxWidth !== undefined) {
                setSmallestSize(smSize)
                setBroadenedFrontText(`0-${maxWidth.maxExtraWidth}mm`)
            }
        }
    }

    /**
     * Setting the the ground sole
     *
     * @param {Object} sole the object to chaange the groundsole to.
     */
    function onGroundSoleSelected (selectedSole) {
        // Get one of the selected soles
        const newGroundSole = dataProvider.soleSpecification.groundSolePatternTypes.find(gs => (gs.name === selectedSole))
        const displayUK = newGroundSole.sizeSystem === 'UK'

        // If the old display and the new display are not the same, empty the footsize fields
        if (sole.displayUKsize !== displayUK) {
            const size = ''
            dispatch(soleData({ key: 'soleSizeLeft', value: size, soleSpecNumber }))
            dispatch(soleData({ key: 'soleSizeRight', value: size, soleSpecNumber }))
            dispatch(soleData({ key: 'footSizeLeft', value: size, soleSpecNumber }))
            dispatch(soleData({ key: 'footSizeRight', value: size, soleSpecNumber }))
            setSmallestSize(size)
        }

        dispatch(soleData({ key: 'displayUKsize', value: displayUK, soleSpecNumber }))
        soleSizeMinMax(sole.block, newGroundSole)

        if (smallestSize !== '') {
            const maxWidth = newGroundSole.fittingData.find(item => parseInt(item.size) === parseInt(smallestSize))
            if (maxWidth !== undefined) setBroadenedFrontText(`0-${maxWidth.maxExtraWidth}mm`)
        }
    }

    /**
     * Setting up the front broadened
     *
     * @param {Object} value value for the front broadened
     */
    function onBroadenedFrontSelected (value) {
        if (value === true) {
            dispatch(soleData({ key: 'broadenedFront', value: 5, soleSpecNumber }))
        } else {
            dispatch(soleData({ key: 'broadenedFront', value: parseInt(value) || '', soleSpecNumber }))
        }
    }

    /**
     * Setting the type of Sole
     * @param {String}
    */
    function onsoleCompletenessSelected (soleValue) {
        dispatch(soleData({ key: 'soleCompleteness', value: soleValue, soleSpecNumber }))
    }
    /**
     * Setting the cover material
     * @param {String} newInputValue Vover material value
     */
    function onCoverMaterialSelected (newInputValue, soles) {
        if (newInputValue === null) {
            dispatch(soleData({ key: 'coverMaterial', value: !newInputValue ? '' : newInputValue, soleSpecNumber }))
            return
        }
        if (newInputValue.includes('Neskrid') && soles[soleSpecNumber].soleData.leftSoleThickness > 0.2) {
            dispatch(soleData({ key: 'leftSoleThickness', value: 0.2, soleSpecNumber }))
            const temp = deepCopy(soles)
            temp[soleSpecNumber].soleData.totalLeftSoleThickness = 1.5
            dispatch(tafelberg({ diagnosis, sole: temp, soleResNumber: soleSpecNumber, side: 'left' }))
        }
        if (newInputValue.includes('Neskrid') && soles[soleSpecNumber].soleData.rightSoleThickness > 0.2) {
            dispatch(soleData({ key: 'rightSoleThickness', value: 0.2, soleSpecNumber }))
            const temp = deepCopy(soles)
            temp[soleSpecNumber].soleData.totalRightSoleThickness = 1.5
            dispatch(tafelberg({ diagnosis, sole: temp, soleResNumber: soleSpecNumber, side: 'right' }))
        }
        dispatch(soleData({ key: 'coverMaterial', value: !newInputValue ? '' : newInputValue, soleSpecNumber }))

        const temp = deepCopy(soles)
        temp[soleSpecNumber].soleData.coverMaterial = newInputValue

        // validate Asc and Fasci Pad
        dispatch(validateAscFasci({ key: 'ascPad', side: 'left', soleResNumber: soleSpecNumber, value: soleResultData.left.ascPad, orderType, sole: temp }))
        dispatch(validateAscFasci({ key: 'fasciPad', side: 'left', soleResNumber: soleSpecNumber, value: soleResultData.left.fasciPad, orderType, sole: temp }))
        dispatch(validateAscFasci({ key: 'ascPad', side: 'right', soleResNumber: soleSpecNumber, value: soleResultData.left.ascPad, orderType, sole: temp }))
        dispatch(validateAscFasci({ key: 'fasciPad', side: 'right', soleResNumber: soleSpecNumber, value: soleResultData.left.fasciPad, orderType, sole: temp }))
    }

    /**
     * Setting the cover level
     * @param {Object} coverLevel value for the cover level
     */
    function onCoverLevelSelected (coverLevel) {
        dispatch(soleData({ key: 'coverLevel', value: coverLevel, soleSpecNumber, diagnosis, addition }))
    }

    /**
     * Setting up the sole type
     *
     * @param {Object} soleType value for the soletype
     */
    function onSoleTypeSelected (soleType) {
        dispatch(soleData({ key: 'soleType', value: soleType, soleSpecNumber }))
    }

    /**
     * Change the left sole thickness height.
     *
     * @param {Number} newInputValue - input value to update left thickness size.
     */
    function onLeftSoleThickness (newInputValue, temp = undefined) {
        if (temp === undefined) {
            temp = deepCopy(soles)
        }
        if (sole.rightSoleThickness === '' || sole.rightSoleThickness === sole.leftSoleThickness) {
            dispatch(soleData({ key: 'rightSoleThickness', value: isNaN(newInputValue) ? 0 : newInputValue, soleSpecNumber, diagnosis }))
            temp[soleSpecNumber].soleData.totalRightSoleThickness = (Number.parseFloat(isNaN(newInputValue) ? 0 : newInputValue) || 0) + 1.3
        }

        dispatch(soleData({ key: 'leftSoleThickness', value: isNaN(newInputValue) ? 0 : newInputValue, soleSpecNumber, diagnosis }))
        temp[soleSpecNumber].soleData.totalLeftSoleThickness = (Number.parseFloat(isNaN(newInputValue) ? 0 : newInputValue) || 0) + 1.3
        dispatch(tafelberg({ diagnosis, sole: temp, soleResNumber: soleSpecNumber, side: 'left' }))
        dispatch(tafelberg({ diagnosis, sole: temp, soleResNumber: soleSpecNumber, side: 'right' }))
    }

    /**
     * Change the right sole thickness height.
     *
     * @param {Number} newInputValue - input value to update right thickness size.
     */
    function onRightSoleThickness (newInputValue, temp = undefined) {
        dispatch(soleData({ key: 'rightSoleThickness', value: isNaN(newInputValue) ? 0 : newInputValue, soleSpecNumber, diagnosis }))

        if (temp === undefined) {
            temp = deepCopy(soles)
        }
        temp[soleSpecNumber].soleData.totalRightSoleThickness = (Number.parseFloat(isNaN(newInputValue) ? 0 : newInputValue) || 0) + 1.3
        dispatch(tafelberg({ diagnosis, sole: temp, soleResNumber: soleSpecNumber, side: 'right' }))
    }

    /**
     * Copy values of sole 0 into this sole.
     */
    function copySole () {
        dispatch(soleData({ key: 'copySole', value: sole0, soleSpecNumber }))
    }

    /**
     * Toggle the right sole of display.
     */
    function toggleDisplayRightSole () {
        dispatch(soleData({ key: 'displayRightSole', value: !sole.displayRightSole, soleSpecNumber }))
    }

    return (
        <Accordion expanded={tab.accordion === true ? (diagnosisTab.validate) : false}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                disabled={(!diagnosisTab.hasBeenOpened || !diagnosisTab.validate)}
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null
                }
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>Zoolspecificaties - Zool {parseInt(soleSpecNumber + 1)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        {
                            soleSpecNumber !== 0
                                ? <Button size={'small'} variant="outlined" className={classes.copySole}
                                    onClick={() => copySole()}>Kopieer zool 1</Button>
                                : null
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={error.block || error.millingSize || error.groundSole}
                        >
                            <FormLabel>Blokkeuze*</FormLabel>
                            <Autocomplete
                                disableClearable={false}
                                className={classes.autoComplete}
                                options={dataProvider.soleSpecification.blockChoices}
                                value={sole.block}
                                onChange={(event, newInputValue) => onBlockSelected(newInputValue)}
                                getOptionLabel={(option) => option.key || ''}
                                isOptionEqualToValue={(option, value) => option.key === value.key}
                                freeSolo
                                renderInput={(params) =>
                                    <TextField
                                        error={error.block || error.millingSize || error.groundSole}
                                        {...params} />
                                } />
                            { importSuggestion.block !== undefined &&
                                <Typography className={classes.undefinedOption}>
                                    Niet beschikbaar: {importSuggestion.block}
                                </Typography>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={error.millingSize}
                        >
                            <FormLabel>Hoogte freesblok (mm)*</FormLabel>
                            <Select
                                value={sole.millingSize}
                                className={classes.autoComplete}
                                onChange={(event) => onMillSelected(event.target.value)}>
                                {
                                    dataProvider.soleSpecification.millSizes.map((size, i) =>
                                        <MenuItem key={i} value={size}>{size}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={error.groundSole}
                        >
                            <FormLabel>Grondzoolpatroontype*</FormLabel>
                            <Select
                                value={sole.groundSole === undefined ? null : sole.groundSole.name}
                                className={classes.autoComplete}
                                onChange={(event) => onGroundSoleSelected(event.target.value)}>
                                {
                                    dataProvider.soleSpecification.groundSolePatternTypes.map((type, i) => {
                                        return <MenuItem key={i} value={type.name}>{type.name}</MenuItem>
                                    })
                                }
                            </Select>
                            { importSuggestion.groundSole !== undefined &&
                                <Typography className={classes.undefinedOption}>
                                    Niet beschikbaar: {importSuggestion.groundSole}
                                </Typography>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            error={error.coverMaterial}>
                            <FormLabel>Afdekmateriaal*</FormLabel>
                            <Autocomplete
                                className={classes.autoComplete}
                                options={dataProvider.soleSpecification.coverMaterials}
                                value={sole.coverMaterial}
                                onChange={(event, newInputValue) => onCoverMaterialSelected(newInputValue, soles)}
                                renderInput={(params) =>
                                    <TextField
                                        error={error.coverMaterial}
                                        {...params} />
                                } />
                            { importSuggestion.coverMaterial !== undefined &&
                                <Typography className={classes.undefinedOption}>
                                    Niet beschikbaar: {importSuggestion.coverMaterial}
                                </Typography>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            error={error.coverLevel}
                        >
                            <FormLabel>Afdekniveau*</FormLabel>
                            <Select
                                disabled={sole.coverMaterial === '-'}
                                value={sole.coverMaterial === '-' ? '' : sole.coverLevel}
                                className={classes.autoComplete}
                                onChange={(event) => onCoverLevelSelected(event.target.value)}
                            >
                                {
                                    Object.keys(diagnosis.completePair === 'both' ? dataProvider.soleSpecification.coverLevels : dataProvider.soleSpecification.coverLevelsCompletePair).map((coverLevel, i) =>
                                        <MenuItem key={i} value={coverLevel}>{coverLevel}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                {soleSpecNumber === 0
                                    ? <Tooltip title="Dit is de voetmaat die je hebt opgemeten met de pasplank">
                                        <FormControl
                                            fullWidth
                                            error={error.footSizeLeft}
                                        >
                                            <FormLabel>{`Voetmaat ${sole.displayRightSole ? 'links' : ''} (EU)*`}</FormLabel>
                                            <Input
                                                type="text"
                                                placeholder={sole.groundSole.name ? sole.groundSole.sizeSystem === 'UK' ? ukToEu(sole.groundSole.min) + '-' + ukToEu(sole.groundSole.max) : sole.groundSole.min + '-' + sole.groundSole.max : '24-50'}
                                                value={sole.footSizeLeft}
                                                inputProps={{ 'data-testid': `leftFootSize${soleSpecNumber}` }}
                                                onChange={(event) => onFootSizeInput(event.target.value)}
                                            />
                                        </FormControl>
                                    </Tooltip>
                                    : <FormControl
                                        fullWidth
                                        error={error.footSizeLeft}
                                    >
                                        <FormLabel>{`Voetmaat ${sole.displayRightSole ? 'links' : ''} (EU)*`}</FormLabel>
                                        <Input
                                            type="text"
                                            placeholder={sole.groundSole.name ? sole.groundSole.sizeSystem === 'UK' ? ukToEu(sole.groundSole.min) + '-' + ukToEu(sole.groundSole.max) : sole.groundSole.min + '-' + sole.groundSole.max : '24-50'}
                                            value={sole.footSizeLeft || ''}
                                            inputProps={{ 'data-testid': `leftFootSize${soleSpecNumber}` }}
                                            onChange={(event) => onFootSizeInput(event.target.value)}
                                        />
                                    </FormControl>}
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip title="Dit is de schoenmaat die je hebt opgemeten">
                                    <FormControl
                                        fullWidth
                                        error={error.soleSizeLeft}
                                    >
                                        <FormLabel>{sole.displayUKsize ? `Zoolmaat ${sole.displayRightSole ? 'links' : ''} (UK)*` : `Zoolmaat ${sole.displayRightSole ? 'links' : ''} (EU)*`}</FormLabel>
                                        <Input
                                            type="text"
                                            inputProps={{ 'data-testid': `leftSoleSize${soleSpecNumber}` }}
                                            placeholder={sole.groundSole.name ? sole.groundSole.min + '-' + sole.groundSole.max : '24-50'}
                                            value={sole.soleSizeLeft || ''}
                                            onChange={(event) => onSoleSizeInput(event.target.value)}
                                        />
                                    </FormControl>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip title="Om extra zooldikte toe te voegen aan de standaard 1.3mm">
                                    <FormControl
                                        fullWidth
                                        error={error.soleThickness || (sole.displayRightSole ? (sole.leftSoleThickness !== '' && soleResultErrors.left.tafelberg) : sole.leftSoleThickness !== '' && (soleResultErrors.left.tafelberg || soleResultErrors.right.tafelberg))}
                                    >
                                        <FormLabel>Extra zooldikte {sole.displayRightSole ? 'links' : ''} (mm)</FormLabel>
                                        <Input
                                            type="text"
                                            inputProps={{ step: '0.1', 'data-testid': `leftSoleThickness${soleSpecNumber}` }}
                                            value={sole.leftSoleThickness || ''}
                                            onChange={event => onLeftSoleThickness(event.target.value.replace(',', '.'))}
                                        />
                                    </FormControl>
                                </Tooltip>
                                {sole.displayRightSole
                                    ? sole.leftSoleThickness !== '' && soleResultErrors.left.tafelberg &&
                                    <Typography className={classes.tafelberg}>
                                        Tafelberg in zoolresultaat.
                                    </Typography>
                                    : sole.leftSoleThickness !== '' && (soleResultErrors.left.tafelberg || soleResultErrors.right.tafelberg) &&
                                    <Typography className={classes.tafelberg}>
                                        Tafelberg in zoolresultaat.
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        {sole.displayRightSole
                            ? <Grid container spacing={4}><Grid item xs={12}>
                                <Tooltip title="Dit is de voetmaat die je hebt opgemeten met de pasplank">
                                    <FormControl
                                        fullWidth
                                        error={error.footSizeRight}
                                    >
                                        <FormLabel>{'Voetmaat rechts (EU)'}</FormLabel>
                                        <Input
                                            type="text"
                                            inputProps={{ 'data-testid': `rightFootSize${soleSpecNumber}` }}
                                            placeholder={sole.groundSole.name ? sole.groundSole.sizeSystem === 'UK' ? ukToEu(sole.groundSole.min) + '-' + ukToEu(sole.groundSole.max) : sole.groundSole.min + '-' + sole.groundSole.max : '24-50'}
                                            value={sole.footSizeRight || ''}
                                            onChange={(event) => onFootSizeInput(event.target.value, true)}
                                        />
                                    </FormControl>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip title="Dit is de schoenmaat die je hebt opgemeten">
                                    <FormControl
                                        fullWidth
                                        error={error.soleSizeRight}
                                    >
                                        <FormLabel>{sole.displayUKsize ? 'Zoolmaat rechts (UK)' : 'Zoolmaat rechts (EU)'}</FormLabel>
                                        <Input
                                            type="text"
                                            inputProps={{ 'data-testid': `rightSoleSize${soleSpecNumber}` }}
                                            placeholder={sole.groundSole.name ? sole.groundSole.min + '-' + sole.groundSole.max : '24-50'}
                                            value={sole.soleSizeRight || ''}
                                            onChange={(event) => onSoleSizeInput(event.target.value, true)}
                                        />
                                    </FormControl>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <Tooltip title="Om extra zooldikte toe te voegen aan de standaard 1.3mm">
                                        <FormControl
                                            fullWidth
                                            error={error.soleThickness || (sole.rightSoleThickness !== '' && soleResultErrors.right.tafelberg)}
                                        >
                                            <FormLabel>Extra zooldikte rechts (mm)</FormLabel>
                                            <Input
                                                type="text"
                                                inputProps={{ step: '0.1' }}
                                                value={sole.rightSoleThickness || ''}
                                                onChange={(event) => onRightSoleThickness(event.target.value.replace(',', '.'))}
                                            />
                                        </FormControl>
                                    </Tooltip>
                                    {sole.rightSoleThickness !== '' && soleResultErrors.right.tafelberg &&
                                            <Typography className={classes.tafelberg}>
                                                Tafelberg in zoolresultaat.
                                            </Typography>
                                    }
                                </Box>
                            </Grid>
                            </Grid>
                            : <Grid container className={classes.buttonGrid} spacing={4} direction="column" justifyContent="center" alignItems="center">
                                <Fab color='secondary' aria-label="add" onClick={toggleDisplayRightSole}>
                                    <Add />
                                </Fab>
                                <Typography className={classes.fabText} variant='subtitle2'>
                                    Verschillende afmetingen links/rechts
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title={`Vul hier de totale extra voorblad breedte in (${broadenedFrontText}, aan beide kanten wordt met de helft hiervan verbreed)`}>
                            <FormControl
                                fullWidth
                                error={error.broadenedFront}
                            >
                                <FormLabel>Verbreed voorblad (mm)</FormLabel>
                                <Input
                                    type="text"
                                    placeholder={broadenedFrontText}
                                    value={sole.broadenedFront || ''}
                                    onChange={(event) => onBroadenedFrontSelected(event.target.value)}
                                />
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl
                            fullWidth
                            error={error.soleCompleteness}>
                            <FormLabel>Soort Zool*</FormLabel>
                            <Autocomplete
                                className={classes.autoComplete}
                                options={dataProvider.soleSpecification.soleCompleteness}
                                value={sole.soleCompleteness}
                                onChange={(event, soleValue) => onsoleCompletenessSelected(soleValue)}
                                renderInput={(params) =>
                                    <TextField
                                        error={error.soleCompleteness}
                                        {...params} />
                                } />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl
                            error={error.soleType}
                            fullWidth>
                            <FormLabel>Zoolkeuze*</FormLabel>
                            <RadioGroup row
                                aria-label="soleChoice"
                                name="sole-select"
                                value={sole.soleType}
                                className={classes.autoComplete}
                                onChange={(event) => onSoleTypeSelected(event.target.value)}>
                                <FormControlLabel value="podozool" control={<Radio />} label="Podozool" />
                                <FormControlLabel value="orthozool" control={<Radio />} label="Orthozool" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {
                        sole.displayRightSole &&
                        <Grid item xs={2}>
                            <Box className={classes.closeRightsole} >
                                <FormControl fullWidth>
                                    <Button variant="contained" onClick={toggleDisplayRightSole}>Sluit rechts</Button>
                                </FormControl>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={3}>
                        <FormControl
                            fullWidth
                        >
                            <FormControlLabel className={classes.checkBoxBottom} label="Allergievrij lijm" control={
                                <Checkbox checked={sole.hypoallergenicGlue} onChange={() => dispatch(soleData({ key: 'hypoallergenicGlue', value: !sole.hypoallergenicGlue }))} />
                            } />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion >
    )
}

export default withStyles(useStyles)(SoleSpecifications)
