import React, { useRef } from 'react'
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    FormGroup,
    FormControl,
    FormLabel,
    Input,
    Grid,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    Autocomplete,
    Select,
    MenuItem,
    Button,
    Tooltip
} from '@mui/material'
import { Add, ExpandMore, Remove } from '@mui/icons-material'
import useStyles from './style'
import data from './diagnose'
import dataProvider from '../../lib/dataProvider'
import { useSelector, useDispatch } from 'react-redux'
import { diagnosisData, diagnosisAccordion } from '../../store/reducers/diagnosis'
import { resetSoleResult, soleResultSuggestion } from '../../store/reducers/soleResult'
import { deepCopy } from '../../lib/util'
import { resetSole, soleData } from '../../store/reducers/soleSpecification'

import { initialState as initSoleRes } from '../../store/reducers/soleResult/initialState'
import { initialState as initSole } from '../../store/reducers/soleSpecification/initialState'
import { withStyles } from '@mui/styles'

const Diagnose = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const diagnosis = useSelector((state) => state.diagnosis.diagnosisData)
    const error = useSelector((state) => state.diagnosis.diagnosisErrors)
    const tab = useSelector((state) => state.diagnosis.diagnosisAccordion)
    const orderType = useSelector((state) => state.general.orderType)
    const soleSpec = useSelector((state) => state.soleSpecification)
    const fits = useSelector((state) => state.fitsSpecification)

    const { current: diagnosesList } = useRef(data.diagnoses)

    /**
     *
     * @param {*} value - the selected diagnostic string
     */
    function onDiagnosisChange (value) {
        if (value === null) value = { name: '' }

        const oldSoleSpec = soleSpec
        const defaultSole = [initSole, initSole, initSole]
        dispatch(resetSole(initSole))
        dispatch(resetSoleResult(initSoleRes))
        dispatch(diagnosisData({ key: 'diagnosis', value }))
        dispatch(soleResultSuggestion({ suggestions: value, side: 'left', sole: defaultSole, diagnosis, orderType, fits, isStance: false }))
        dispatch(soleResultSuggestion({ suggestions: value, side: 'right', sole: defaultSole, diagnosis, orderType, fits, isStance: false }))
        updateTherapyMessage(value.soleTherapy !== 0)

        oldSoleSpec.forEach((oldSole, soleSpecNumber) => {
            if (oldSole.soleData.footSizeLeft !== 0) {
                dispatch(soleData({ key: 'footSizeLeft', value: oldSole.soleData.footSizeLeft, soleSpecNumber }))
                dispatch(soleData({ key: 'footSizeRight', value: oldSole.soleData.footSizeRight, soleSpecNumber }))
            }
        })

        // if stance diff is set update the values
        onLeftStanceDifferenceChange(diagnosis.leftStanceDifference)
        onRightStanceDifferenceChange(diagnosis.rightStanceDifference)
    }

    /**
     * Update the therapy message
     */
    function updateTherapyMessage (needsTherapy) {
        if (needsTherapy) {
            if (orderType === 'fits') {
                dispatch(diagnosisData({
                    key: 'therapyMessage',
                    value: 'FITS therapie'
                }))
            } else dispatch(diagnosisData({ key: 'therapyMessage', value: 'Zolentherapie' }))
        } else {
            if (orderType === 'fits') {
                dispatch(diagnosisData({
                    key: 'therapyMessage',
                    value: 'Geen FITS therapie'
                }))
            } else dispatch(diagnosisData({ key: 'therapyMessage', value: 'Geen zolentherapie' }))
        }
    }

    /**
     * Update the amount of soles.
     *
     * @param {string} numberOfSoles .
     */
    function onNumberOfSolesChange (numberOfSoles) {
        dispatch(diagnosisData({ key: 'numberOfSoles', value: numberOfSoles }))

        const copyDiagnosis = deepCopy(diagnosis)
        copyDiagnosis.numberOfSoles = numberOfSoles

        // update soleResultSuggestions
        dispatch(soleResultSuggestion({ suggestions: diagnosis.diagnosis, side: 'left', sole: soleSpec, diagnosis, orderType, fits, isStance: false }))
        dispatch(soleResultSuggestion({ suggestions: diagnosis.diagnosis, side: 'right', sole: soleSpec, diagnosis, orderType, fits, isStance: false }))
        onLeftStanceDifferenceChange(diagnosis.leftStanceDifference)
        onRightStanceDifferenceChange(diagnosis.rightStanceDifference)
    }

    /**
     * Update if there is a different stance difference between the left and the right sole.
     *
     * @param {Boolean} differentStance .
     */
    function differentStances (differentStance) {
        dispatch(diagnosisData({ key: 'displayRightStanceDifference', value: differentStance }))
        dispatch(diagnosisData({ key: 'symmetrical', value: !differentStance }))
    }

    /**
     * Update if there is a different stance difference between the left and the right sole.
     *
     * @param {Boolean} differentStance .
     */
    function setSymmetrical (symmetrical) {
        dispatch(diagnosisData({ key: 'symmetrical', value: !diagnosis.symmetrical }))
    }

    /**
     * Change the left stance height.
     *
     * @param {Number} selectedDifference - input value to update left.
     */
    function onLeftStanceDifferenceChange (selectedDifference) {
        if (selectedDifference === null) selectedDifference = {}
        dispatch(diagnosisData({ key: 'leftStanceDifference', value: selectedDifference }))
        if (diagnosis.displayRightStanceDifference) {
            dispatch(soleResultSuggestion({ suggestions: selectedDifference, side: 'left', sole: soleSpec, diagnosis, orderType, fits, isStance: true }))
        } else {
            dispatch(soleResultSuggestion({ suggestions: selectedDifference, side: 'left', sole: soleSpec, diagnosis, orderType, fits, isStance: true }))
            dispatch(soleResultSuggestion({ suggestions: selectedDifference, side: 'right', sole: soleSpec, diagnosis, orderType, fits, isStance: true }))
        }
    }

    /**
     * Change the right stance height.
     *
     * @param {Number} selectedDifference - input value to update right.
     */
    function onRightStanceDifferenceChange (selectedDifference) {
        if (selectedDifference === null) selectedDifference = {}
        dispatch(diagnosisData({ key: 'rightStanceDifference', value: selectedDifference }))
        dispatch(soleResultSuggestion({ suggestions: selectedDifference, side: 'right', sole: soleSpec, diagnosis, orderType, fits, isStance: true }))
    }

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(diagnosisAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    /**
    * Change number of extra diagnosises
    */
    function addExtraDiagnosis () {
        const temp = deepCopy(diagnosis.extraDiagnosis)
        temp.push({
            name: ''
        })
        dispatch(diagnosisData({ key: 'extraDiagnosis', value: temp }))
    }

    /**
    * Change number of extra diagnosises
    */
    function onExtraDiagnosisChange (value, index) {
        const temp = deepCopy(diagnosis.extraDiagnosis)
        temp[index] = value
        dispatch(diagnosisData({ key: 'extraDiagnosis', value: temp }))
    }

    /**
    * Change number of extra diagnosis
    */
    function removeExtraDiagnosis () {
        const temp = deepCopy(diagnosis.extraDiagnosis)
        const num = temp.length - 1
        temp.pop()
        if (num >= 0) {
            dispatch(diagnosisData({ key: 'extraDiagnosis', value: temp }))
        }
    }

    /**
     * Display the number of extra diagnosis
     */
    function displayExtraDiagnosis () {
        const jsx = []
        diagnosis.extraDiagnosis.forEach((element, i) => {
            jsx.push(
                <Grid item xs={6}>
                    <FormControl
                        fullWidth>
                        <FormLabel>Extra diagnose {i + 2}</FormLabel>
                        <Autocomplete
                            title="selectDiagnose"
                            value={diagnosis.extraDiagnosis[i]}
                            onChange={(event, value) => onExtraDiagnosisChange(value, i)}
                            options={diagnosesList}
                            className={classes.autoComplete}
                            getOptionLabel={(option) => option.name || ''}
                            renderInput={(params) =>
                                <TextField
                                    {...params} />
                            } />
                    </FormControl>
                </Grid>
            )
        })
        return jsx
    }

    return (
        <Accordion expanded={tab.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>Diagnose</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup className={classes.fullWidth}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.diagnosis}>
                                <FormLabel>Diagnose*</FormLabel>
                                <Autocomplete
                                    title="selectDiagnose"
                                    value={diagnosis.diagnosis}
                                    onChange={(event, value) => onDiagnosisChange(value)}
                                    options={diagnosesList}
                                    className={classes.autoComplete}
                                    getOptionLabel={(option) => option.name || ''}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.diagnosis}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Therapie</FormLabel>
                                <Input type="text" disabled
                                    inputProps={{ 'data-testid': 'therapyMessage' }}
                                    value={diagnosis.therapyMessage} />
                            </FormControl>
                        </Grid>

                        {displayExtraDiagnosis()}
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item>
                                    <Tooltip title="Deze diagnose heeft geen invloed op element suggesties">
                                        <Button onClick={() => addExtraDiagnosis()} variant="outlined" color='secondary' startIcon={<Add />}>
                                        extra diagnose
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => removeExtraDiagnosis()} variant="outlined" color='secondary' startIcon={<Remove />}>
                                    extra diagnose
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                            >
                                <FormGroup>
                                    <FormControlLabel className={classes.checkBox} control={<Checkbox checked={diagnosis.displayRightStanceDifference} onChange={(event, value) => differentStances(value)} />} label='Verschil in standsafwijking links/rechts' />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                            >
                                <FormGroup>
                                    <FormControlLabel className={classes.checkBox} control={<Checkbox disabled={diagnosis.displayRightStanceDifference} checked={!diagnosis.symmetrical} onChange={(event) => setSymmetrical()} />} label='Verschillende elementen links/rechts' />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.leftStanceDifference}
                            >
                                <FormLabel>Standsafwijking {diagnosis.displayRightStanceDifference ? 'links' : null}*</FormLabel>
                                <Autocomplete
                                    title="stanceDifferences"
                                    value={diagnosis.leftStanceDifference}
                                    onChange={(event, value) => onLeftStanceDifferenceChange(value)}
                                    options={dataProvider.diagnosis.stanceDifferences}
                                    className={classes.autoComplete}
                                    getOptionLabel={(option) => option.name || ''}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.leftStanceDifference}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        {diagnosis.displayRightStanceDifference && <Grid item xs={6}>

                            <FormControl
                                fullWidth
                                error={error.rightStanceDifference}
                            >
                                <FormLabel>Standsafwijking {diagnosis.displayRightStanceDifference ? 'rechts' : null}*</FormLabel>
                                <Autocomplete
                                    value={diagnosis.rightStanceDifference}
                                    onChange={(event, value) => onRightStanceDifferenceChange(value)}
                                    options={dataProvider.diagnosis.stanceDifferences}
                                    className={classes.autoComplete}
                                    getOptionLabel={(option) => option.name || ''}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.rightStanceDifference}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        }
                        {!diagnosis.displayRightStanceDifference && orderType !== 'insole' && <Grid item xs={6}></Grid>}
                        {orderType === 'insole' && <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Volledig paar</FormLabel>
                                <FormControl fullWidth>
                                    <Select
                                        className={classes.autoComplete}
                                        value={diagnosis.completePair}
                                        onChange={(event) => { dispatch(diagnosisData({ key: 'completePair', value: event.target.value })) }}
                                    >
                                        <MenuItem value={'both'}>Ja</MenuItem>
                                        <MenuItem value={'left'}>Nee, alleen links</MenuItem>
                                        <MenuItem value={'right'}>Nee, alleen rechts</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormControl>
                        </Grid>
                        }
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Aantal {orderType === 'fits' ? 'FITS' : 'zolen'}*</FormLabel>
                                <RadioGroup
                                    row
                                    value={diagnosis.numberOfSoles}
                                    aria-label="soleChoice" name="sole-select"
                                    color='secondary'
                                    onChange={event => onNumberOfSolesChange(event.target.value)}>
                                    <FormControlLabel value="1" control={<Radio />} label="1" />
                                    <FormControlLabel value="2" control={<Radio />} label="2" />
                                    <FormControlLabel value="3" control={<Radio />} label="3" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(Diagnose)
