import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    successPaper: {
        backgroundColor: '#5ACC02',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: '100%',
        borderRadius: 5
    },
    successTypography: {
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '15px',
        textAlign: 'center'
    },
    successSnackbar: {
        width: '60%',
        borderRadius: 5
    }
}))

export default styles
