import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    dialogButton: {
        background: 'linear-gradient(-20deg, #0094D1 30%, #00B8EE 90%)',
        marginRight: 'auto',
        marginLeft: 'unset',
        color: '#ffffff',
        fontWeight: 'bold',
        boxShadow: '0 0px 0px 0px rgba(255, 255, 255, 0)',
        '&:hover': {
            boxShadow: '0 0px 0px 0px rgba(255, 255, 255, 0)'
        }
    },
    dialogContinueButton: {
        background: 'linear-gradient(-20deg, #EE7203 30%, #EE8E37 90%)',
        marginLeft: '0',
        marginRight: 'unset'
    }
}))

export default styles
