export const ListVcwAdditions = [
    'Pelotte 3D Boven',
    'ASC EVA Camel 5mm Onder',
    'ASC EVA Camel 10mm Onder',
    'SA1 PPT 3mm Boven',
    'SA1-5 PPT 3mm Boven',
    'SA2-5 PPT 3mm Boven',
    'SA1 PPT 5mm Boven',
    'SA1-5 PPT 5mm Boven',
    'SA2-5 PPT 5mm Boven'
]
