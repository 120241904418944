{
  "name": "sim-app",
  "version": "1.8.2",
  "private": true,
  "license": "UNLICENSED",
  "dependencies": {
    "@date-io/moment": "^1.3.13",
    "@emotion/react": "^11.11.1",
    "@emotion/styled": "^11.11.0",
    "@mui/base": "^5.0.0-beta.20",
    "@mui/icons-material": "^5.14.14",
    "@mui/lab": "^5.0.0-alpha.149",
    "@mui/material": "^5.14.14",
    "@mui/styles": "^5.14.14",
    "@mui/system": "^5.14.14",
    "@mui/x-date-pickers": "^6.16.2",
    "@react-three/drei": "^9.88.4",
    "@react-three/fiber": "^8.14.2",
    "@reduxjs/toolkit": "^1.9.7",
    "axios": "^0.27.2",
    "closest-match": "^1.3.3",
    "esbuild": "^0.19.5",
    "esbuild-plugin-inline-image": "^0.0.9",
    "eslint-config-standard": "^17.1.0",
    "exceljs": "^4.4.0",
    "jszip": "^3.10.1",
    "keycloak-js": "^21.1.1",
    "moment": "^2.29.4",
    "notistack": "^3.0.1",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-hotkeys-hook": "^3.4.6",
    "react-redux": "^8.1.3",
    "react-router-dom": "^6.17.0",
    "react-scripts": "^5.0.1",
    "redux": "^4.2.1",
    "three": "^0.144.0",
    "web-vitals": "^1.1.2"
  },
  "scripts": {
    "start": "react-scripts start",
    "build": "node build.js",
    "test": "jest --verbose --silent=false",
    "testWatch": "jest --watchAll --verbose --silent=false",
    "eject": "react-scripts eject"
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@babel/preset-env": "^7.22.20",
    "@testing-library/jest-dom": "^5.15.0",
    "@testing-library/react": "^11.2.7",
    "@testing-library/user-event": "^12.8.3",
    "@types/jest": "^29.5.3",
    "eslint": "^8.23.1",
    "eslint-config-standard": "^17.1.0",
    "eslint-plugin-import": "^2.26.0",
    "eslint-plugin-n": "^15.3.0",
    "eslint-plugin-promise": "^6.0.1",
    "eslint-plugin-react": "^7.31.8",
    "jest": "^29.6.1"
  }
}
