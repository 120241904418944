export const fullTable = [
    { id: 'MIC', element: 'MIC (mm)', type: 'number' },
    { id: 'RCTB', element: 'RCTB (mm)', type: 'number' },
    { id: 'ASC', element: 'ASC (mm)', type: 'number' },
    { id: 'pelotte', element: 'Pelotte (mm)', type: 'number' },
    { id: 'CV', element: 'CV (mm)', type: 'number' },
    { id: 'fasciPad', element: 'Fasci Opvulling', type: 'select', values: ['true', 'false'], displayValue: ['Ja', 'Nee'] },
    { id: 'ascPad', element: 'Ascendens Opvulling', type: 'select', values: ['true', 'false'], displayValue: ['Ja', 'Nee'] },
    { id: 'LFW', element: 'Laterale voorvoet wig / LFW (mm)', type: 'number' },
    { id: 'MFW', element: 'Mediale voorvoet wig / MFW (mm)', type: 'number' },
    { id: 'PPSI', element: 'Laterale achtervoet wig / PPSI (mm)', type: 'number' },
    { id: 'SPSI', element: 'Mediale achtervoet wig / SPSI (mm)', type: 'number' },
    { id: 'PT', element: 'PT (mm)', type: 'number' },
    { id: 'SA1', element: 'SA 1 (mm)', type: 'number' },
    { id: 'SA25', element: 'SA 2-5 (mm)', type: 'number' },
    { id: 'SA15', element: 'SA 1-5 (mm)', type: 'number' }
]
export const allRows = [
    'MIC',
    'RCTB',
    'ASC',
    'pelotte',
    'CV',
    'fasciPad',
    'ascPad',
    'LFW',
    'MFW',
    'PPSI',
    'PT',
    'SA1',
    'SA25',
    'SA15',
    'SPSI'
]

export const elementHeightList = {
    MIC: {
        min: 18,
        max: 30
    },
    RCTB: {
        min: 5,
        max: 5
    },
    ASC: {
        min: 2,
        max: 7
    },
    pelotte: {
        min: 4,
        max: 6
    },
    CV: {
        min: 0,
        max: 30
    },
    PPSI: {
        min: 2,
        max: 6
    },
    PT: {
        min: 0,
        max: 15
    },
    SA15: {
        min: 0,
        max: 3
    },
    SPSI: {
        min: 2,
        max: 6
    },
    MFW: {
        min: 2,
        max: 6
    },
    LFW: {
        min: 2,
        max: 6
    },
    SA25: {
        min: 0,
        max: 8
    },
    SA1: {
        min: 0,
        max: 5
    }
}
