import { configureStore } from '@reduxjs/toolkit'
import generalSlice from './reducers/general'
import diagnosisSlice from './reducers/diagnosis'
import soleSpecificationSlice from './reducers/soleSpecification'
import fitsSpecificationSlice from './reducers/fitsSpecification'
import soleResult from './reducers/soleResult'
import soleAddition from './reducers/soleAddition'
import other from './reducers/other'
import importSuggestion from './reducers/importSuggestion'

const store = configureStore({
    reducer: {
        general: generalSlice,
        diagnosis: diagnosisSlice,
        soleSpecification: soleSpecificationSlice,
        fitsSpecification: fitsSpecificationSlice,
        soleResult,
        soleAddition,
        other,
        importSuggestion
    },
    // removes a 'non-serializable value was detected in the state' error when selecting a date
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export default store
