import React, { useEffect, useState } from 'react'
import {
    Grid,
    Card,
    FormControlLabel,
    FormGroup,
    Checkbox,
    CardContent,
    Typography,
    Button,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Tooltip
} from '@mui/material'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { podoITexport } from '../../lib/exportService'
import { rhinoExport } from '../../lib/rhinoService'
import { unityExport } from '../../lib/unityService'
import { generateCSV } from '../../lib/csvService'
import { Help, DriveFolderUpload, Save } from '@mui/icons-material'
import useStyles from './style'
import clsx from 'clsx'
import moment from 'moment'
import AppTitle from '../../components/AppTitle'
import api from '../../lib/api'
import SnackbarComponentSuccess from '../../components/SnackbarComponentSuccess'
import JSZip from 'jszip'
import { withStyles } from '@mui/styles'
import { changeAllValid, changeErrorDialog } from '../../store/reducers/other'
import { LoadingButton } from '@mui/lab'
import UserService from '../../lib/userService'

// 3d viewer
// import { Canvas } from '@react-three/fiber'
// import { OrbitControls, Stage } from '@react-three/drei'
// import { Model } from './Model'

const Overview = ({ classes, showAppTitle }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const ref = useRef()

    const [showResearcherDownload, setResearcherDownload] = useState(false)
    const [displayError, setDisplayError] = useState(false)
    const [sharepointError, setSharepointError] = useState(false)
    const [unityError, setUnityError] = useState(false)
    const [displaySnackbar, setDisplaySnackbar] = useState(false)
    const [sendToPodoIT, setSendToPodoIT] = useState(true)
    const [sendToSharepoint, setSendToSharepoint] = useState(UserService.hasRole('sharepoint-tester'))
    const [disableSend, setDisableSend] = useState(false)
    const [disableSendSharepoint, setDisableSendSharepoint] = useState(!UserService.hasRole('sharepoint-tester'))
    const [downloadUnity, setDownloadUnity] = useState(false)
    const [apiError, setApiError] = useState('')
    const [helpDialog, setHelpDialog] = useState(false)

    const orderType = useSelector((state) => state.general.orderType)
    const diagnosis = useSelector((state) => state.diagnosis.diagnosisData)
    const patient = useSelector((state) => state.general.patientData)
    const soles = useSelector((state) => state.soleSpecification)
    const fits = useSelector((state) => state.fitsSpecification)
    const soleRes = useSelector((state) => state.soleResult)
    const other = useSelector((state) => state.other)

    const fullState = useSelector((state) => state)

    useHotkeys('shift+d', () => showFunctions())

    useEffect(() => {
        // substitute the Smart Insole Modeling title to
        // Bestellingsoverzicht
        showAppTitle(false)
        if (other.dataProvider === 'fits-comfort') {
            setSendToPodoIT(false)
            setDisableSend(true)
        }

        return () => {
            // show back the Smart Insole Modeling title
            showAppTitle(true)
        }
    }, [])

    /**
     * Enable special features
     */
    function showFunctions () {
        setResearcherDownload(true)
        // setSendToSharepoint(true)
    }

    /**
     * Generate a filename.
     *
     * @return {String} - the generatedd filename
     */
    function generateFileName () {
        const today = moment().format('YYYY-MM-DD')
        const hrs = moment().format('hh:mm:ss')
        return `${today}_${hrs}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}.json`
    }

    /**
     * Download all files.
     */
    function downloadJson () {
        downloadJsonGeneral()
        downloadJsonUnity()
        downloadJsonRhino()
        downloadCSV()
        downloadXLSX()
    }

    /**
     * Download rhino file.
     */
    function downloadJsonRhino () {
        const rhinoModel = rhinoExport(fullState)
        const rhinoButton = document.createElement('a')
        const today = moment().format('YYYY-MM-DD')
        const hrs = moment().format('hh-mm-ss')
        rhinoButton.download = `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}-rhino.json`
        rhinoButton.href = `data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(rhinoModel, null, '\t')
        )}`

        if (!sendToSharepoint) {
            // start download
            rhinoButton.click()
        } else {
            api.uploadToSharepoint(rhinoModel, `${today}_${hrs}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}-rhino.json`, onSuccessSharepoint, onErrorSharepoint)
        }
    }

    /**
     * Download unity file.
     */
    function downloadJsonUnity () {
        setDownloadUnity(true)
        if (sendToPodoIT) {
            const generalModel = podoITexport(fullState)
            api.placeOrder(generalModel, onSuccess, onError)
        }

        const unityModel = { soleInput: unityExport(fullState) }
        api.postInsole(unityModel, onSuccessUnity, onErrorUnity)
    }

    /**
     * Download CSV file.xr
     */
    function downloadCSV () {
        for (let i = 0; i < diagnosis.numberOfSoles; i++) {
            const csvButton = document.createElement('a')

            const today = moment().format('YYYY-MM-DD')
            const csvFileName = `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}_${i + 1}`
            csvButton.download = `${csvFileName}.csv`

            const rhinoFileName = `${i + 1}_${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}-rhino`
            const encodedUri = encodeURI(generateCSV(fullState, rhinoFileName, i, true))
            csvButton.href = encodedUri
            // start download
            csvButton.click()
        }
    }

    /**
     * d
     */
    function downloadXLSX () {
        const today = moment().format('YYYY-MM-DD')
        // for (let i = 0; i < diagnosis.numberOfSoles; i++) {
        //     excelExport(fullState, i, `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}_${i + 1}.xlsx`)
        // }
        import('../../lib/excelService')
            .then(({ excelExport }) => {
                excelExport(fullState, 0, `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}_1.xlsx`)
            })
            .catch(err => {
                alert(`Kon geen excel genereren \n ${err}`)
            })
    }

    /**
     * Download full state export
     */
    function downloadJsonGeneral () {
        const generalModel = podoITexport(fullState)
        const today = moment().format('YYYY-MM-DD')
        const generalButton = document.createElement('a')
        generalButton.download = `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}-general.json`
        generalButton.href = `data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(generalModel, null, '\t')
        )}`
        // start download
        generalButton.click()
    }

    /**
     * Download full state export
     */
    function downloadJsonGeneralError () {
        const generalModel = podoITexport(fullState)
        generalModel.error = apiError
        const today = moment().format('YYYY-MM-DD')
        const generalButton = document.createElement('a')
        generalButton.download = `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}-general.json`
        generalButton.href = `data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(generalModel, null, '\t')
        )}`
        // start download
        generalButton.click()
    }

    /**
     * Return to homepage
     */
    function returnToStart () {
        if (UserService.hasRole('sharepoint-tester')) {
            window.location.replace(api.accountLinkUrl)
        } else {
            window.location.replace('/')
        }
    }

    /**
     * place podo-it order
     */
    function toPodoIT () {
        if (sendToPodoIT) {
            const generalModel = podoITexport(fullState)
            api.placeOrder(generalModel, onSuccess, onError)
        }
        if (soles[0].soleData.block.key === '3D Sport Printzool') {
            downloadXLSX()
        } else {
            downloadJsonRhino()
        }
    }

    /**
     * Display success snackbar
     *
     * @param {Object} param - succes param
     */
    function onSuccess (param) {
        setDisplaySnackbar(true)
        setDisableSend(true)
        setSendToPodoIT(false)
    }

    /**
     * Display success snackbar
     *
     * @param {Object} param - succes param
     */
    function onSuccessSharepoint (param) {
        setDisableSendSharepoint(true)
        setSendToSharepoint(false)
    }

    /**
     * Display success snackbar
     *
     * @param {Object} param - succes param
     */
    function onErrorSharepoint (param) {
        setDisableSendSharepoint(true)
        setSendToSharepoint(false)
        downloadJsonRhino()

        setSharepointError(true)
        setApiError(param)
    }

    /**
     * Display success snackbar
     *
     * @param {Object} param - succes param
     */
    async function onSuccessUnity (param) {
        setDownloadUnity(false)
        const zip = new JSZip()

        const today = moment().format('YYYY-MM-DD')
        const defaultName = `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}`
        zip.file(`${defaultName}-rhino.json`, JSON.stringify(rhinoExport(fullState), null, '\t'))

        for (let index = 0; index < param.Results.length; index++) {
            const sole = atob(param.Results[index].Sole)
            const binaryString = sole
            const len = binaryString.length
            const bytes = new Uint8Array(len)
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i)
            }

            const blob = new Blob([bytes], {
                type: 'application/octet-stream'
            })

            const formData = {
                patient: {
                    firstName: 'test',
                    gender: 'test',
                    lastName: 'test2',
                    number: 'test',
                    birthDate: '2024-10-31T13:33:55.071Z'
                },
                insole: {
                    isLeftFoot: true,
                    modelFileData: param.Results[index].Sole
                }
            }

            api.testPS2D(formData)

            if (index % 2 !== 0) {
                const subFilename = `zool${Math.round(index / 2 - 1) + 1}`
                const folder = zip.folder(subFilename)

                folder.file(`${subFilename}_rechts.obj`, blob)
            } else {
                const subFilename = `zool${Math.round(index / 2) + 1}`

                const csvFileName = `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}_${Math.round(index / 2) + 1}`
                const folder = zip.folder(subFilename)

                const rhinoFileName = `${Math.round(index / 2) + 1}_${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}-rhino`
                const csv = generateCSV(fullState, rhinoFileName, Math.round(index / 2))

                folder.file(`${subFilename}_links.obj`, blob)
                folder.file(`${csvFileName}.csv`, csv)
            }
        }

        const zipContent = await zip.generateAsync({ type: 'blob' })

        const link = document.createElement('a')
        link.href = URL.createObjectURL(zipContent)
        link.download = `${defaultName}.zip`
        link.click()
    }

    /**
     * callback function called when the
     * snackbar should close
     */
    function onSnackbarClose () {
        setDisplaySnackbar(false)
    }

    /**
     * Display error snackbar
     *
     * @param {Object} param - error param.
     */
    function onError (param) {
        setDisplayError(true)
        setApiError(param)
    }

    /**
     * Display error snackbar
     *
     * @param {Object} param - error param.
     */
    function onErrorUnity (param) {
        setUnityError(true)
        setApiError(param)
        setDownloadUnity(false)
    }

    /**
     * Open help modal.
     */
    function openHelp () {
        downloadJsonGeneralError()
        setHelpDialog(true)
    }

    /**
     * close the help modal.
     */
    function closeHelpDialog () {
        setHelpDialog(false)
    }
    /**
     *
     */
    function backButton () {
        navigate(-1)
        dispatch(changeErrorDialog(false))
        dispatch(changeAllValid(false))
    }

    /**
     * open sharepoint problemen export.
     */
    function openSharepointError () {
        const generalButton = document.createElement('a')
        generalButton.target = '_blank'
        generalButton.href = 'https://voetencentrumwender590.sharepoint.com/Gedeelde%20%20documenten/Forms/AllItems.aspx?e=5%3A2c118a17f39149f48563a107f0ac46fc&at=9&CT=1665658381351&OR=OWA%2DNT&CID=09dc71ba%2D62e2%2D3caf%2D7f8b%2D53ece4adf7e0&FolderCTID=0x01200078508E6DABD2C34580DB837F6DF09001&id=%2FGedeelde%20%20documenten%2FCentraal%20modelleren%20%28project%29%2FExportsWebapplicatieSlimModelleren%2FProblemenExport&viewid=e9227840%2Dfcdc%2D489a%2Da100%2De354f7426710'
        generalButton.click()
    }

    /**
     * Set send to podoIt true or false.
     *
     * @param {Boolean} event - bool
     */
    function setChecked (event) {
        setSendToPodoIT(event.target.checked)
    };

    /**
     * Set send to podoIt true or false.
     *
     * @param {Boolean} event - bool
     */
    function setCheckedSharepoint (event) {
        setSendToSharepoint(event.target.checked)
    };

    /**
     * Display buttons to continue with
     *
     * @param {Object} hasDownloadJson - boolean
     * @return {JSX} - button bar with objects.
     */
    function ButtonBar ({ hasDownloadJson }) {
        return <div>
            {displayError &&
                <Grid className={classes.errorPaper} container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item>
                        <Typography className={classes.errorTypography}>Er is iets misgegaan, klik op help voor meer informatie</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color='error' startIcon={<Help />} onClick={openHelp}>
                            HELP
                        </Button>
                    </Grid>
                </Grid>
            }
            {sharepointError &&
            <Grid className={classes.errorPaper} container direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item>
                    <Typography>Er is iets met de sharepoint upload, probeer handmatig uploaden</Typography>
                </Grid>
            </Grid>
            }
            {unityError &&
                <Grid className={classes.warningPaper} container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item>
                        <Typography>Er is iets met de unity export, probeer het opnieuw. Als dit vaker voorkomt mail: slim@podotherapeut.nl </Typography>
                    </Grid>
                </Grid>
            }
            <Grid container className={classes.buttonBar} justifyContent="space-between">
                <Grid xs={2} container direction="column">
                    <Button color='warning' variant="contained"
                        onClick={backButton}>
                        Terug
                    </Button>
                </Grid>
                <Grid xs={2} container direction="column">
                    <Button
                        variant="contained"
                        onClick={returnToStart}
                        color='success'
                    >
                        Nieuwe klant
                    </Button>
                </Grid>
                <Grid xs={2} container direction="column">
                    <Button
                        variant="contained"
                        onClick={toPodoIT}
                        color='primary'
                    >
                        Verstuur
                    </Button>
                    <FormGroup>
                        <Tooltip title={ disableSend ? (other.dataProvider === 'fits-comfort' ? '' : 'De export is opgestuurd, hij kan niet opnieuw verstuurd worden') : ''}>
                            <FormControlLabel control={<Checkbox disabled={disableSend} checked={sendToPodoIT} onChange={setChecked} />} label={`Stuur naar ${other.backend.type === 'orthoit' ? 'Ortho-IT' : 'Podo-IT'}`} />
                        </Tooltip>
                        {UserService.hasRole('sharepoint-tester') &&
                        <FormControlLabel control={<Checkbox disabled={disableSendSharepoint} checked={sendToSharepoint} onChange={setCheckedSharepoint} />} label={'Stuur naar sharepoint'} />}
                    </FormGroup>
                </Grid>
                {/* <Button variant="contained" className={classes.button}
                    onClick={onSubmit}>
                    Bevestigen
                </Button> */}
            </Grid>

            {hasDownloadJson &&
                <Grid container className={classes.buttonBar} justifyContent="space-between">
                    <Grid key={1} xs={2} container direction="column">
                        <Button variant="contained" color='primary'
                            onClick={downloadJson}>
                            Researcher
                        </Button>
                    </Grid>
                    <Grid key={1} xs={2} container direction="column">
                        <Button variant="contained" color='success'
                            onClick={downloadXLSX}>
                            Printsole
                        </Button>
                    </Grid>
                    <Grid key={1} xs={2} container direction="column">
                        <LoadingButton loadingPosition="start" startIcon={<Save />} loading={downloadUnity} variant="contained" onClick={downloadJsonUnity} color='error' >
                            Test unity
                        </LoadingButton>
                    </Grid>
                </Grid>
            }
        </div>
    }

    /**
     * Get a sole view.
     *
     * @returns {JSX} display all the soles.
     */
    function displaySoles () {
        const displaySoles = []
        for (let i = 0; i < diagnosis.numberOfSoles; i++) {
            displaySoles.push(
                <div>
                    <Typography className={classes.title} variant="h5" component="h5">
                        Zoolspecificaties - {i + 1}
                    </Typography>
                    <Table key={i} className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography>Blokkeuze</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{soles[i].soleData.block.key ? soles[i].soleData.block.key : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Hoogte Freesblok</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {soles[i].soleData.millingSize ? soles[i].soleData.millingSize : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Grondzoolpatroontype</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{soles[i].soleData.groundSole.name}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Afdekmateriaal</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {soles[i].soleData.coverMaterial ? soles[i].soleData.coverMaterial : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Afdekniveau</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {soles[i].soleData.coverLevel ? soles[i].soleData.coverLevel : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Voetmaat Links/ Rechts</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {soles[i].soleData.footSizeLeft ? soles[i].soleData.footSizeLeft : ''}
                                        / {soles[i].soleData.footSizeRight ? soles[i].soleData.footSizeRight : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Zoolmaat Links/ Rechts</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {soles[i].soleData.soleSizeLeft ? soles[i].soleData.soleSizeLeft : ''}
                                        / {soles[i].soleData.soleSizeRight ? soles[i].soleData.soleSizeRight : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Extra Zooldikte Links/ Rechts</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {soles[i].soleData.leftSoleThickness ? soles[i].soleData.leftSoleThickness : '0.0'}
                                        / {soles[i].soleData.rightSoleThickness ? soles[i].soleData.rightSoleThickness : '0.0'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Totale Zooldikte Links/ Rechts</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {soles[i].soleData.totalLeftSoleThickness ? soles[i].soleData.totalLeftSoleThickness : '0'}
                                        / {soles[i].soleData.totalRightSoleThickness ? soles[i].soleData.totalRightSoleThickness : '0'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Verbreding Voorblad</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {soles[i].soleData.broadenedFront ? soles[i].soleData.broadenedFront : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Soort Zool</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {soles[i].soleData.soleCompleteness ? soles[i].soleData.soleCompleteness : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Zoolkeuze</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{soles[i].soleData.soleType}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            )
        }

        return displaySoles
    }

    /**
     * Get a FITS overview of all the data.
     *
     * @returns {JSX} display all the soles.
     */
    function displayFits () {
        const displayFits = []
        for (let i = 0; i < diagnosis.numberOfSoles; i++) {
            displayFits.push(
                <div>
                    <Typography className={classes.title} variant="h5" component="h5">
                        FITS specificaties - {i + 1}
                    </Typography>
                    <Table key={i} className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography>Model</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{fits[i].fitsData.model.displayValue ? fits[i].fitsData.model.displayValue : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Hoogte Freesblok</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {fits[i].fitsData.upperMaterial ? fits[i].fitsData.upperMaterial : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Afdekmateriaal</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {fits[i].fitsData.coverMaterial ? fits[i].fitsData.coverMaterial : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Blokkeuze</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {fits[i].fitsData.block ? fits[i].fitsData.block : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Loopzool</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {fits[i].fitsData.outsole ? fits[i].fitsData.outsole : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Afwerkingsvorm</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {fits[i].fitsData.finishingShape ? fits[i].fitsData.finishingShape : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Voetmaat Links/ Rechts</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {fits[i].fitsData.footSizeLeft ? fits[i].fitsData.footSizeLeft : ''}
                                        / {fits[i].fitsData.footSizeRight ? fits[i].fitsData.footSizeRight : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Wijdtemaat Links/ Rechts</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {fits[i].fitsData.leftSoleWidth ? fits[i].fitsData.leftSoleWidth : '0'}
                                        / {fits[i].fitsData.rightSoleWidth ? fits[i].fitsData.rightSoleWidth : '0'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Wijdte Links/ Rechts</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {fits[i].fitsData.leftSoleWidthMessage ? fits[i].fitsData.leftSoleWidthMessage : ''}
                                        / {fits[i].fitsData.rightSoleWidthMessage ? fits[i].fitsData.rightSoleWidthMessage : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Externe hielheffing Links/ Rechts</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {soleRes[i].soleResultData.left.externalHeelLift ? soleRes[i].soleResultData.left.externalHeelLift : ''}
                                        / {soleRes[i].soleResultData.right.externalHeelLift ? soleRes[i].soleResultData.right.externalHeelLift : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Extra Element</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {fits[i].fitsData.extraElement.includes('30sh 3mm EVA - zwart') ? '30sh 3mm EVA - zwart' : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Zoolkeuze</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{soles[i].soleData.soleType}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            )
        }

        return displayFits
    }

    return (
        <div>
            <AppTitle title={'Bestellingsoverzicht'} />
            <ButtonBar hasDownloadJson={showResearcherDownload} />
            <Card variant="outlined">
                <CardContent>
                    <Typography className={classes.title} variant="h5" component="h5">
                        Algemene Patientgegevens
                    </Typography>

                    {/* <Canvas shadowMap camera={{ fov: 50 }} style={{ width: '800px', height: '600px', margin: 'auto' }}>
                        <Suspense fallback={null}>
                            <Stage controls={ref} >
                                <Model node={node} />
                            </Stage>
                        </Suspense>
                        <OrbitControls ref={ref} />
                    </Canvas> */}
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography>Ingezonden freesfile</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{generateFileName()}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Patiëntnaam</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient ? patient.lastName : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>

                                    <Typography>Patiëntnummer</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient ? patient.number : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>

                                    <Typography>Geboortedatum</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient ? moment(patient.birthdate).format('DD/MM/YYYY') : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Geslacht</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient.gender === 'male' ? 'Man' : 'Vrouw'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Aanmeetlocatie</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {patient.fittingLocation
                                            ? patient.fittingLocation.title
                                            : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Ontvangstlocatie</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {patient.receiveLocation
                                            ? patient.receiveLocation.title
                                            : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {orderType === 'fits'
                                ? null
                                : <TableRow>
                                    <TableCell>
                                        <Typography>Spoedbestelling</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {patient.emergencyOrder ? 'Ja' : 'Nee'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                    {orderType === 'fits' ? displayFits() : displaySoles()}
                </CardContent>
            </Card>
            <Card variant="outlined"></Card>
            <div className={classes.bottomPageSpacer}>
                <ButtonBar hasDownloadJson={showResearcherDownload} />
            </div>

            <SnackbarComponentSuccess display={displaySnackbar}
                message={'Successvol verstuurd naar Podo-IT'}
                onClose={onSnackbarClose}
            />
            <Dialog
                open={helpDialog}
                onClose={closeHelpDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    Versturen naar Podo-IT is misgegaan, wat nu?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography>
                                    <a style={{ fontWeight: 700 }}>Stap 1:</a> Sla de &apos;...-rhino.json&apos; zoals altijd op in de &apos;Te modelleren&apos; map op Sharepoint.
                                </Typography>
                                <Typography>
                                    Als deze stap gelukt is worden de zolen gewoon besteld, dit probleem heeft dan geen gevolgen voor het bestelproces
                                </Typography>

                                <br></br>
                                <Typography>
                                    <a style={{ fontWeight: 700 }}>Stap 2:</a> Sla de &apos;...-general.json&apos; op in de map &apos;ProblemenExport&apos;.
                                </Typography>
                                <Typography>
                                    De &apos;...-general.json&apos; wordt automatisch gedownload, wanneer dit niet zo is zet dan &apos;...-rhino.json&apos; ook de in map &apos;ProblemenExport&apos;.
                                </Typography>
                                <Typography>
                                    Klik op de knop &apos;ProblemenExport&apos; om de folder te openen.
                                </Typography>
                                <br></br>
                                <Button
                                    variant="contained"
                                    color='error'
                                    className={clsx([classes.buttonDowload, classes.button])}
                                    onClick={openSharepointError}
                                    startIcon={<DriveFolderUpload />} >
                                    ProblemenExport
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        className={clsx([classes.buttonBack, classes.button])}
                        onClick={closeHelpDialog}>Sluiten</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withStyles(useStyles)(Overview)
