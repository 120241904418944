/**
 * Validate if locations for additions have not been covered by Covering Layer.
 */
function validateCoverLayerObstruction (state, location, soleSpec, number, soleNr) {
    let message = ''
    if (soleSpec.soleData.coverMaterial === '-') return
    switch (location) {
    case 'left':
        message = `Error 601: Voor de linkerzool van zool ${soleNr + 1} moet het afdekmateriaal los bijgeleverd worden`
        if (soleSpec.soleData.coverLevel === 'Volledig afdekken' || soleSpec.soleData.coverLevel.includes('Links afdekken')) {
            state.additionErrors.message.push(message)
            state.additionErrors.addition[number] = true
        }
        break
    case 'right':
        message = `Error 601: Voor de rechterzool van zool ${soleNr + 1} moet het afdekmateriaal los bijgeleverd worden`
        if (soleSpec.soleData.coverLevel === 'Volledig afdekken' || soleSpec.soleData.coverLevel.includes('Rechts afdekken')) {
            state.additionErrors.message.push(message)
            state.additionErrors.addition[number] = true
        }
        break
    case 'both':
        message = `Error 601: Voor beide zolen van zool ${soleNr + 1} moet het afdekmateriaal los bijgeleverd worden`
        if (soleSpec.soleData.coverLevel !== 'Los bijleveren') {
            state.additionErrors.message.push(message)
            state.additionErrors.addition[number] = true
        }
        break
    default:
        break
    }
}

/**
 * Checks if the cover layer aligns with a top aanvulling.
 */
export function validateTopCoverLayer (state, action, key) {
    const number = action.payload.number
    const name = state.additionData.addition[number].name
    const location = state.additionData.addition[number].location
    const forSoles = state.additionData.addition[number].forSoles
    const numberOfSoles = action.payload.numberOfSoles
    const soleSpec = action.payload.soleSpecification
    const value = action.payload.value ? action.payload.value : ''

    state.additionErrors.display = false
    state.additionErrors.message = []
    state.additionErrors.addition[number] = false

    switch (key) {
    case 'soleAddition':
        if (value.includes('Boven')) {
            for (let i = 0; i < numberOfSoles; i++) {
                if (forSoles.includes(i)) {
                    validateCoverLayerObstruction(state, location, soleSpec[i], number, i)
                }
            }
        }
        break
    case 'soleLocation':
        if (name.includes('Boven')) {
            for (let i = 0; i < numberOfSoles; i++) {
                if (forSoles.includes(i)) {
                    validateCoverLayerObstruction(state, value, soleSpec[i], number, i)
                }
            }
        }
        break
    case 'addSole':
        if (name.includes('Boven')) {
            for (let i = 0; i < numberOfSoles; i++) {
                if (value.includes(i)) {
                    validateCoverLayerObstruction(state, location, soleSpec[i], number, i)
                }
            }
        }
        break
    }
}

/**
 * Validate if fields are not empty.
*/
export function validateAllFields (state, action) {
    const messages = state.additionErrors.message
    const index = messages.indexOf('Error 602: Zoollogistiek kan de aanvulling niet toevoegen als de zolen naar een locatie verstuurd worden. Dit kan alleen als je ervoor kiest om de zolen naar de patiënt te laten sturen.')
    state.additionErrors.message = messages.splice(index + 1, 1)

    const receiveLocation = action.payload.value.general.patientData.receiveLocation
    if (receiveLocation.id !== -1 && receiveLocation.id !== -12) {
        const addition = state.additionData.addition[0]
        if (addition !== undefined && addition.name !== '' && addition.location !== '' && addition.forSoles.length > 0) {
            state.additionErrors.message.push('Error 602: Zoollogistiek kan de aanvulling niet toevoegen als de zolen naar een locatie verstuurd worden. Dit kan alleen als je ervoor kiest om de zolen naar de patiënt te laten sturen.')
        }
    }
    state.additionAccordion.validate = !checkEmptyFields(state)
}

/**
 * Check if the fields of soleAddition are empty.
 */
function checkEmptyFields (state) {
    const logistic = state.additionData.addition
    let notFilled = false
    logistic.forEach((addition, i) => {
        if (i === 0) {
            if (addition.name === '' && addition.location === '' && addition.forSoles.length === 0) {
                notFilled = false
            } else if (((addition.name !== '' || addition.name !== null) && addition.location === '' && addition.forSoles.length === 0) ||
                ((addition.name !== '' || addition.name !== null) && addition.location !== '' && addition.forSoles.length === 0) ||
                ((addition.name !== '' || addition.name !== null) && addition.location === '' && addition.forSoles.length !== 0) ||
                ((addition.name === '' || addition.name === null) && addition.location !== '' && addition.forSoles.length === 0) ||
                ((addition.name === '' || addition.name === null) && addition.location !== '' && addition.forSoles.length !== 0) ||
                ((addition.name === '' || addition.name === null) && addition.location === '' && addition.forSoles.length !== 0)) {
                notFilled = true
            }
        } else if ((addition.name === '' || addition.name === null) || addition.location === '' || addition.forSoles.length === 0) {
            notFilled = true
        }
    })
    return state.additionErrors.message.length !== 0 || notFilled
}
