export const fullTable = [
    { id: 'MIC', element: 'MIC (mm)', type: 'number' },
    { id: 'MICType', element: 'MIC variatie', type: 'select', values: ['High', 'Neutral', 'Low'], displayValue: ['Bol', 'Neutraal', 'Hol'] },
    { id: 'MICPosition', element: 'MIC positie hoogste punt', type: 'select', values: ['Os naviculare', 'TMT - 1'], displayValue: ['Os naviculare', 'TMT - 1'] },
    { id: 'RCTB', element: 'RCTB (mm)', type: 'number' },
    { id: 'ASC', element: 'ASC (mm)', type: 'number' },
    { id: 'pelotte', element: 'Pelotte (mm)', type: 'number' },
    { id: 'pelotteType', element: 'Pelotte type', type: 'dropdown', values: ['pelotte', 'pelotteCM12', 'pelotteCM23', 'pelotteCM34', 'pelotteCM45'], displayValue: ['Pelotte', 'Interdigitale pelotte 1-2', 'Interdigitale pelotte 2-3', 'Interdigitale pelotte 3-4', 'Interdigitale pelotte 4-5'] },
    { id: 'CV', element: 'CV (mm)', type: 'number' },
    { id: 'fasciPad', element: 'Fasci Opvulling', type: 'select', values: ['true', 'false'], displayValue: ['Ja', 'Nee'] },
    { id: 'ascPad', element: 'Ascendens Opvulling', type: 'select', values: ['true', 'false'], displayValue: ['Ja', 'Nee'] },
    { id: 'LFW', element: 'Laterale voorvoet wig / LFW (mm)', type: 'number' },
    { id: 'MFW', element: 'Mediale voorvoet wig / MFW (mm)', type: 'number' },
    { id: 'PPSI', element: 'Laterale achtervoet wig / PPSI (mm)', type: 'number' },
    { id: 'SPSI', element: 'Mediale achtervoet wig / SPSI (mm)', type: 'number' },
    { id: 'PA', element: 'PA (mm)', type: 'number' },
    { id: 'PT', element: 'PT (mm)', type: 'number' },
    { id: 'SA1', element: 'SA 1 (mm)', type: 'number' },
    { id: 'SA25', element: 'SA 2-5 (mm)', type: 'number' },
    { id: 'SA15', element: 'SA 1-5 (mm)', type: 'number' }
]

export const allRows = [
    'MIC',
    'RCTB',
    'ASC',
    'pelotte',
    'CV',
    'fasciPad',
    'ascPad',
    'LFW',
    'MFW',
    'PPSI',
    'PA',
    'PT',
    'SA1',
    'SA25',
    'SA15',
    'SPSI'
]

export const elementHeightList = {
    MIC: {
        min: 16,
        max: 35
    },
    RCTB: {
        min: 0,
        max: 10
    },
    ASC: {
        min: 0,
        max: 20
    },
    pelotte: {
        min: 0,
        max: 10
    },
    CV: {
        min: 0,
        max: 30
    },
    PPSI: {
        min: 0,
        max: 15
    },
    PT: {
        min: 0,
        max: 15
    },
    SA15: {
        min: 0,
        max: 8
    },
    SPSI: {
        min: 0,
        max: 15
    },
    MFW: {
        min: 0,
        max: 8
    },
    LFW: {
        min: 0,
        max: 8
    },
    PA: {
        min: 0,
        max: 10
    },
    SA25: {
        min: 0,
        max: 8
    },
    SA1: {
        min: 0,
        max: 5
    }
}
