export const initialState = {
    soleResultData: {
        left: {
            MIC: '',
            MICType: 'Neutral',
            MICPosition: 'Os naviculare',
            RCTB: '',
            ASC: '',
            pelotte: '',
            pelotteType: '',
            CV: '',
            PPSI: '',
            PT: '',
            SA15: '',
            SPSI: '',
            MFW: '',
            LFW: '',
            PA: '',
            SA25: '',
            SA1: '',
            fasciPad: 'false',
            ascPad: 'false',
            tafelberg: '',
            externalHeelLift: 0,
            relief: []
        },
        right: {
            MIC: '',
            MICType: 'Neutral',
            MICPosition: 'Os naviculare',
            RCTB: '',
            ASC: '',
            pelotte: '',
            pelotteType: '',
            CV: '',
            PPSI: '',
            PT: '',
            SA15: '',
            SPSI: '',
            MFW: '',
            LFW: '',
            PA: '',
            SA25: '',
            SA1: '',
            fasciPad: 'false',
            ascPad: 'false',
            tafelberg: '',
            externalHeelLift: 0,
            relief: []
        }
    },
    soleResultSuggestion: {
        left: {
            MIC: '',
            RCTB: '',
            ASC: '',
            pelotte: '',
            pelotteType: '',
            CV: '',
            PPSI: '',
            PT: '',
            SA15: '',
            SPSI: '',
            MFW: '',
            LFW: '',
            PA: '',
            SA25: '',
            SA1: '',
            fasciPad: 'false',
            ascPad: 'false'
        },
        right: {
            MIC: '',
            RCTB: '',
            ASC: '',
            pelotte: '',
            pelotteType: '',
            CV: '',
            PPSI: '',
            PT: '',
            SA15: '',
            SPSI: '',
            MFW: '',
            LFW: '',
            PA: '',
            SA25: '',
            SA1: '',
            fasciPad: 'false',
            ascPad: 'false'
        }
    },
    soleResultAccordion: {
        notAsSuggested: false,
        accordion: false,
        hasBeenOpened: false,
        left: {
            validate: true
        },
        right: {
            validate: true
        },
        visibleElements: ['MIC', 'MICType', 'MICPosition', 'RCTB', 'ASC', 'pelotte', 'pelotteType', 'CV']
    },
    soleResultErrors: {
        message: [],
        left: {
            tafelberg: false,
            MIC: '',
            RCTB: '',
            ASC: '',
            pelotte: '',
            pelotteType: '',
            CV: '',
            PPSI: '',
            PT: '',
            SA15: '',
            SPSI: '',
            MFW: '',
            LFW: '',
            PA: '',
            SA25: '',
            SA1: '',
            fasciPad: '',
            ascPad: '',
            externalHeelLift: false,
            relief: ''
        },
        right: {
            tafelberg: false,
            MIC: '',
            RCTB: '',
            ASC: '',
            pelotte: '',
            pelotteType: '',
            CV: '',
            PPSI: '',
            PT: '',
            SA15: '',
            SPSI: '',
            MFW: '',
            LFW: '',
            PA: '',
            SA25: '',
            SA1: '',
            fasciPad: '',
            ascPad: '',
            externalHeelLift: false,
            relief: ''
        }
    },
    soleResultDisabled: {
        left: {
            MIC: false,
            RCTB: false,
            ASC: false,
            pelotte: false,
            CV: false,
            PPSI: false,
            PT: false,
            SA15: false,
            SPSI: false,
            MFW: false,
            LFW: false,
            PA: false,
            SA25: false,
            SA1: false,
            fasciPad: false,
            ascPad: false
        },
        right: {
            MIC: false,
            RCTB: false,
            ASC: false,
            pelotte: false,
            CV: false,
            PPSI: false,
            PT: false,
            SA15: false,
            SPSI: false,
            MFW: false,
            LFW: false,
            PA: false,
            SA25: false,
            SA1: false,
            fasciPad: false,
            ascPad: false
        }
    }
}
