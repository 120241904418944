// import UserService from './userService'

class DataProvider {
    /**
     * Constructor function
     */
    constructor () {
        this.folder = 'vcw'
    }

    /**
     * Get the diagnosis
     *
     * @returns a pallete
     */
    get diagnosis () {
        let diagnosis = null
        switch (this.folder) {
        case 'printsole':
            diagnosis = require('./data/printsole/diagnosis')
            break
        default:
            diagnosis = require('./data/vcw/diagnosis')
            break
        }
        return diagnosis
    }

    /**
     * Get the diagnosis
     *
     * @returns a pallete
     */
    get fitsSpecification () {
        let fitsSpecification = null
        switch (this.folder) {
        case 'printsole':
            fitsSpecification = require('./data/printsole/fitsSpecification')
            break
        case 'fits-comfort':
            fitsSpecification = require('./data/fits-comfort/fitsSpecification')
            break
        default:
            fitsSpecification = require('./data/vcw/fitsSpecification')
            break
        }
        return fitsSpecification
    }

    /**
     * Get the diagnosis
     *
     * @returns a pallete
     */
    get soleAddition () {
        let soleAddition = null
        switch (this.folder) {
        case 'printsole':
            soleAddition = require('./data/printsole/soleAddition')
            break
        default:
            soleAddition = require('./data/vcw/soleAddition')
            break
        }
        return soleAddition
    }

    /**
     * Get the diagnosis
     *
     * @returns a pallete
     */
    get soleResult () {
        let soleResult = null
        switch (this.folder) {
        case 'printsole':
            soleResult = require('./data/printsole/soleResult')
            break
        default:
            soleResult = require('./data/vcw/soleResult')
        }
        return soleResult
    }

    /**
     * Get the diagnosis
     *
     * @returns a pallete
     */
    get soleSpecification () {
        let soleSpecification = null
        switch (this.folder) {
        case 'printsole':
            soleSpecification = require('./data/printsole/soleSpecification')
            break
        case 'Kievit Orthopedie':
            soleSpecification = require('./data/kievit-ortho/soleSpecification')
            break
        default:
            soleSpecification = require('./data/vcw/soleSpecification')
        }
        return soleSpecification
    }

    /**
     *
     * @param {*} value
     */
    setFolder (value) {
        this.folder = value
    }

    /**
     *
     * @returns
     */
    getFolder () {
        return this.folder
    }
}

// eslint-disable-next-line new-cap
export default new DataProvider()
