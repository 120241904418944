import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    root: {
        height: '100%'
    },
    fullWidth: {
        minWidth: '100%'
    },
    loading: {
        margin: 0
    },
    autoComplete: {
        marginTop: 16
    },
    title: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    info: {
        color: theme.palette.primary.main
    },
    accordionError: {
        backgroundColor: theme.palette.error.light
    },
    accordionSuccess: {
        backgroundColor: theme.palette.success.light
    },
    titleError: {
        fontWeight: 'bold',
        color: theme.palette.error.main
    },
    titleSuccess: {
        fontWeight: 'bold',
        color: theme.palette.success.main
    },
    displayNone: {
        display: 'none'
    },
    checkBox: {
        marginLeft: 0,
        marginRight: 0
    }
}))

export default styles
