/**
 * Performs a deep copy on the object.
 * This preserves any non-object types
 *
 * @param {T} object the object to copy
 * @returns {T} the copy
 * @template T
 */
function deepCopy (object) {
    if (typeof object !== 'object') { return object }
    if (object === null) { return null }

    const out = Array.isArray(object) ? [] : {}
    for (const key in object) {
        out[key] = deepCopy(object[key])
    }
    return out
}

/**
 * Get prefix of environment
 *
 * @param {Object} backend - other.backend variable
 * @returns the given Prefix for that environment
 */
function getPrefix (backend) {
    switch (backend.key) {
    case 'Penders':
        return 'P'
    case 'Voetmax':
        return 'VM'
    case 'Kievit Orthopedie':
        return ''
    default:
        return ''
    }
}

/**
 *
 * @param {Array} arr -
 * @returns
 */
function combineItems (arr) {
    const combinations = {
        CM1: 'CM1',
        CM2: 'CM2',
        CM3: 'CM3',
        CM4: 'CM4',
        CM5: 'CM5',
        // CM1CM5: 'CM15', // deze moet worden cm1 cm5 los.
        CM2CM3: 'CM23',
        CM3CM4: 'CM34',
        CM4CM5: 'CM45',
        CM2CM3CM4: 'CM234',
        CM3CM4CM5: 'CM345'
    }

    const result = []
    const availableItems = [...arr]

    for (let i = 0; i < availableItems.length; i++) {
        const currentItem = availableItems[i]
        let longestCombination = currentItem // Initialize with the current item

        for (let j = i + 1; j < availableItems.length; j++) {
            const combinedItem = longestCombination + availableItems[j]
            if (combinations[combinedItem]) {
                longestCombination = combinedItem // Update longest combination found
                i = j // Skip checking shorter combinations
            }
        }

        if (!result.find(item => item.includes(currentItem))) {
            result.push(combinations[longestCombination])
        }
    }

    return result
}

/**
 * Convert the UK size to the EU size
 *
 * @param {Number} ukSize - the UK size
 * @return {Number} - the EU size
 */
function ukToEu (ukSize) {
    const initialSize = 1.27 * (ukSize + 23) + 2
    const euSize = Math.round(initialSize.toFixed(1) * 2, 1) / 2
    return euSize
}

/**
 * Convert the EU size to the UK size
 *
 * @param {Number} euSize - the EU size
 * @return {Number} - the UK size
 */
function euToUk (euSize) {
    const initialSize = (euSize - 2) / 1.27 - 23 // (1.27 * ukSize) + 32.51
    const ukSize = Math.round(initialSize.toFixed(1) * 2, 1) / 2
    return ukSize
}

export { deepCopy, ukToEu, euToUk, getPrefix, combineItems }
