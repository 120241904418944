import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { filledInField, availableSizes, validateAllFields, validateSoleWidthSize, validateModelFilled, validateSize, validateSoleThickness } from './validation.js'

const fitsSpecification = createSlice({
    name: 'fitsSpec',
    initialState: [
        { ...initialState },
        { ...initialState },
        { ...initialState }
    ],
    reducers: {
        fitsData (state, action) {
            const fitsNr = action.payload.fitsSpecNumber
            if (fitsNr !== undefined) {
                fitsDataFunc(state, action)
            } else {
                state.forEach((element, i) => {
                    action.payload.fitsSpecNumber = i
                    fitsDataFunc(state, action)
                })
            }
        },
        fitsAccordion (state, action) {
            const fitsNr = action.payload.fitsSpecNumber
            const key = action.payload.key
            switch (key) {
            case 'accordion':
                state[fitsNr].fitsAccordion.accordion = action.payload.value
                if (!state[fitsNr].fitsAccordion.hasBeenOpened) {
                    state[fitsNr].fitsAccordion.hasBeenOpened = action.payload.value
                } else {
                    validateAllFields(state, fitsNr)
                }
                break
            case 'validate':
                state[fitsNr].fitsAccordion.validate = action.payload.value
                break
            case 'hasBeenOpened':
                state[fitsNr].fitsAccordion.validate = action.payload.value
                state.generalAccordion.hasBeenOpened = true
                break
            }
        },
        importFits (state, action) {
            // check if import is male or female.
            state.forEach((element, i) => {
                const soleNr = i
                if (action.payload[soleNr] !== undefined && action.payload[soleNr].fitsData !== undefined) {
                    state[soleNr].fitsData = action.payload[soleNr].fitsData
                } else if (action.payload[soleNr] !== undefined) {
                    state[soleNr].fitsData = action.payload[soleNr]
                }
            })
        },
        closeFits (state) {
            state.forEach((element, i) => {
                const fitsNr = i
                state[fitsNr].fitsAccordion.accordion = false
                state[fitsNr].fitsAccordion.hasBeenOpened = true
                validateAllFields(state, fitsNr)
            })
        }
    }
})

/**
 * Fits function
 *
 * @param {Object} state - current redux states
 * @param {Object} action - action containing fits data.
 */
function fitsDataFunc (state, action) {
    const fitsNr = action.payload.fitsSpecNumber
    const value = action.payload.value
    const key = action.payload.key
    switch (key) {
    case 'copyFits':
        state[fitsNr].fitsData = value
        break
    case 'displayRightSole':
        state[fitsNr].fitsData[key] = value
        break
    case 'footSizeLeft':
        state[fitsNr].fitsData.footSizeLeft = value
        validateSize(state, fitsNr, value, true, false)
        break
    case 'footSizeRight':
        state[fitsNr].fitsData.footSizeRight = value
        validateSize(state, fitsNr, value, true, true)
        break
    case 'soleSizeLeft':
        state[fitsNr].fitsData.soleSizeLeft = value
        validateSize(state, fitsNr, value, false, false)
        availableSizes(state, fitsNr)
        break
    case 'soleSizeRight':
        state[fitsNr].fitsData.soleSizeRight = value
        validateSize(state, fitsNr, value, false, true)
        availableSizes(state, fitsNr)
        break
    case 'leftSoleWidth':
        state[fitsNr].fitsData[key] = value
        filledInField(state, fitsNr, key)
        validateModelFilled(state, fitsNr, false)
        break
    case 'rightSoleWidth':
        state[fitsNr].fitsData[key] = value
        filledInField(state, fitsNr, key)
        validateModelFilled(state, fitsNr, true)
        break
    case 'leftSoleWidthMessage':
        state[fitsNr].fitsData[key] = value
        filledInField(state, fitsNr, key)
        validateSoleWidthSize(state, fitsNr, value, false)
        break
    case 'rightSoleWidthMessage':
        state[fitsNr].fitsData[key] = value
        filledInField(state, fitsNr, key)
        validateSoleWidthSize(state, fitsNr, value, true)
        break
    case 'extraElement':
        state[fitsNr].fitsData.extraElement = value
        break
    case 'totalLeftSoleThickness':
        state[fitsNr].fitsData[key] = value
        validateSoleThickness(state, fitsNr, value)
        break
    case'totalRightSoleThickness':
        state[fitsNr].fitsData[key] = value
        validateSoleThickness(state, fitsNr, value)
        break
    default:
        state[fitsNr].fitsData[key] = value
        filledInField(state, fitsNr, key)
        break
    }
}

export const { closeFits, fitsData, fitsAccordion, importFits } = fitsSpecification.actions
export default fitsSpecification.reducer
