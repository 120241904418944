import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    root: {
        height: '100%',
        flexGrow: 1
    },
    container: {
        height: 440,
        maxHeight: 440
    },
    table: {
        // minWidth: 650,
    },
    loading: {
        margin: 0
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.light
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    title: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    errorText: {
        color: theme.palette.error.main
    },
    accordionError: {
        backgroundColor: theme.palette.error.light
    },
    accordionSuccess: {
        backgroundColor: theme.palette.success.light
    },
    titleError: {
        fontWeight: 'bold',
        color: theme.palette.error.main
    },
    titleSuccess: {
        fontWeight: 'bold',
        color: `${theme.palette.success.main}!important`
    },
    maxCellWidth: {
        maxWidth: 200,
        minWidth: 200
    },
    suggestion: {
        maxWidth: 150,
        minWidth: 150
    },
    tafelbergError: {
        color: `${theme.palette.error.main}!important`,
        fontWeight: '700!important',
        borderBottomWidth: 0
    },
    selected: {
        backgroundColor: '#e9f8fc'
    },
    copySole: {
        marginRight: theme.spacing(2)
    },
    tafelbergSuccess: {
        color: `${theme.palette.success.main}!important`,
        borderBottomWidth: 0
    },
    addElementBtn: {
        marginTop: 10
    }
}))

export default styles
