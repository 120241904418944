export const initialState = {
    fitsData: {
        model: '',
        upperMaterial: '',
        coverMaterial: '',
        upperPosition: 'Standaard',
        block: 'EVA 50sh - 38mm - zwart',
        millingSize: 38,
        outsole: '',
        finishingShape: 'Volledig afgewerkt',
        footSizeLeft: '',
        footSizeRight: '',
        soleSizeLeft: '',
        soleSizeRight: '',
        leftSoleWidth: '',
        leftSoleWidthMessage: '',
        rightSoleWidth: '',
        rightSoleWidthMessage: '',
        extraElement: [],
        roughenSole: false,
        totalLeftSoleThickness: 4, // fits-custom is 5, fits-comfort is 4
        totalRightSoleThickness: 4,
        soleType: 'podozool',
        displayRightSole: false,
        hypoallergenicGlue: false
    },
    fitsAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    fitsErrors: {
        display: false,
        message: [],
        model: false,
        upperMaterial: false,
        coverMaterial: false,
        block: false,
        outsole: false,
        finishingShape: false,
        upperPosition: false,
        footSizeLeft: false,
        footSizeRight: false,
        leftSoleWidth: false,
        rightSoleWidth: false,
        leftSoleWidthMessage: false,
        rightSoleWidthMessage: false,
        totalLeftSoleThickness: false,
        totalRightSoleThickness: false
    }
}
