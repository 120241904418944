import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({

    ApplicationTitle: {
        color: theme.palette.primary.main,
        fontFamily: 'Roboto-Light!important'
    },
    titleContainer: {
        padding: theme.spacing(8)
    },
    svg: {
        width: 100,
        marginRight: theme.spacing(6)
    }
}))

export default styles
