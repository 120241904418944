import { createSlice } from '@reduxjs/toolkit'
import { validateAllFields, validateLeftRightStanceDifference, validateOldSoleImport } from './validation.js'
import { initialState } from './initialState.js'

const diagnosisSlice = createSlice({
    name: 'diagnosis',
    initialState,
    reducers: {
        diagnosisData (state, action) {
            const key = action.payload.key
            const value = action.payload.value
            switch (key) {
            case 'copyResult':
                state.diagnosisData = value
                break
            default:
                state.diagnosisData[key] = action.payload.value
                state.diagnosisErrors[key] = false
                break
            }

            validateLeftRightStanceDifference(state)
            validateAllFields(state)
        },
        diagnosisAccordion (state, action) {
            switch (action.payload.key) {
            case 'accordion':
                state.diagnosisAccordion.accordion = action.payload.value
                if (!state.diagnosisAccordion.hasBeenOpened) {
                    state.diagnosisAccordion.hasBeenOpened = true
                } else {
                    validateAllFields(state, action)
                }
                break
            case 'validate':
                state.diagnosisAccordion.validate = action.payload.value
                break
            case 'hasBeenOpened':
                state.diagnosisAccordion.hasBeenOpened = true
                break
            }
        },
        closeDiagnosis (state) {
            state.diagnosisAccordion.accordion = false
            state.diagnosisAccordion.hasBeenOpened = true
            validateAllFields(state)
        },
        importDiagnosis (state, action) {
            importDiagnosisFunc(state, action)
        },
        setError (state, action) {
            state.diagnosisErrors.display = true
            state.diagnosisErrors.message = action.payload.message
        },
        resetDiagnosis (state, action) {
            state.diagnosisData = action.payload.diagnosisData
            state.diagnosisErrors = action.payload.diagnosisErrors
        }
    }
})

/**
 * Set sole data function
 *
 * @param {Obejct} state - current redux state.
 * @param {Object} action - action containing data.
 */
export function importDiagnosisFunc (state, action) {
    const diagnosis = action.payload.diagnosis.diagnosis.name
    if (action.payload.version <= '1.5.2') {
        if (diagnosis !== undefined) {
            state.diagnosisData = action.payload.diagnosis
            validateOldSoleImport(state, 'diagnosisChange')
        } else {
            state.diagnosisData = action.payload.diagnosis
            state.diagnosisData.diagnosis = {
                name: ''
            }
            state.diagnosisData.therapyMessage = ''
            validateOldSoleImport(state, 'diagnosisGone')
        }
    } else {
        state.diagnosisData = action.payload.diagnosis
        validateOldSoleImport(state, 'diagnosisUnchanged')
    }
}

export const { diagnosisData, diagnosisAccordion, closeDiagnosis, importDiagnosis, resetDiagnosis, setError } = diagnosisSlice.actions
export default diagnosisSlice.reducer
