import dataProvider from '../../../lib/dataProvider'
/**
 * Checks if FITS model is filled in.
 */
export function validateModelFilled(state, fitsNr, rightSide) {

    const fits = state[fitsNr].fitsData
    if (rightSide) {
        if (fits.model === '') {
            state[fitsNr].fitsErrors.message.push('Error 701: Vul eerst het model in voordat je de wijdtemaat invoert')
            state[fitsNr].fitsErrors.rightSoleWidth = true
            state[fitsNr].fitsErrors.model = true
            return
        }
    } else {
        if (fits.model === '') {
            state[fitsNr].fitsErrors.message.push('Error 701: Vul eerst het model in voordat je de wijdtemaat invoert')
            state[fitsNr].fitsErrors.leftSoleWidth = true
            state[fitsNr].fitsErrors.model = true
            return
        }
    }

    state[fitsNr].fitsErrors.message = state[fitsNr].fitsErrors.message.filter(e => e.substring(0, 9) !== 'Error 701')
}

/**
 * Checks if sole width is not too big or too small.
 */
export function validateSoleWidthSize(state, fitsNr, value, rightSide) {
    const widthMin = 20
    const isTeenslipper = state[fitsNr].fitsData.model.key !== undefined && state[fitsNr].fitsData.model.key.includes('teenslipper')
    const widthMax = isTeenslipper ? 54 : 45.5
    const addValue = rightSide ? state[fitsNr].fitsData.footSizeRight * 1.5 : state[fitsNr].fitsData.footSizeLeft * 1.5
    if (value === 'Te Klein') {
        state[fitsNr].fitsErrors.message.push(`Error 702: Ingevulde wijdtemaat is te klein. Vul minimaal een waarde van ${widthMin + addValue} mm in.`)
        if (rightSide) { state[fitsNr].fitsErrors.rightSoleWidthMessage = true } else { state[fitsNr].fitsErrors.leftSoleWidthMessage = true }
        return
    } else if (value === 'Te Groot') {
        state[fitsNr].fitsErrors.message.push(`Error 703: Ingevulde wijdtemaat is te groot. Vul maximaal een waarde van ${widthMax + addValue} mm in.`)
        if (rightSide) { state[fitsNr].fitsErrors.rightSoleWidthMessage = true } else state[fitsNr].fitsErrors.leftSoleWidthMessage = true
        return
    }
    // Write an exclusion for left and right.
    let activeMessageLarge = false
    let activeMessageSmall = false
    if (rightSide) {
        activeMessageLarge = state[fitsNr].fitsData.leftSoleWidthMessage === 'Te Groot'
        activeMessageSmall = state[fitsNr].fitsData.leftSoleWidthMessage === 'Te Klein'
    } else {
        activeMessageLarge = state[fitsNr].fitsData.rightSoleWidthMessage === 'Te Groot'
        activeMessageSmall = state[fitsNr].fitsData.rightSoleWidthMessage === 'Te Klein'
    }

    if (!activeMessageSmall) {
        state[fitsNr].fitsErrors.message = state[fitsNr].fitsErrors.message.filter(e => e.substring(0, 9) !== 'Error 702')
    }

    if (!activeMessageLarge) {
        state[fitsNr].fitsErrors.message = state[fitsNr].fitsErrors.message.filter(e => e.substring(0, 9) !== 'Error 703')
    }
}
/**
 * Checks if a field if it's filled in.
 *
 * @param {Objecty} state - current state.
 * @param {Number} fitsNr - fitsNumber.
 */
export function filledInField(state, fitsNr, key) {
    const fitsError = state[fitsNr].fitsErrors
    const fits = state[fitsNr].fitsData

    fitsError[key] = !fits[key]
}

/**
 * Set size error
 *
 * @param {Object} state - current state.
 * @param {Number} fitsNr - soleNumber
 * @param {Object} errorState - given state to be set to..
 * @param {Boolean} isFootSize - true if footsize is filled.
 * @param {Boolean} rightSize - true if rightSize is entered.
 */
function setSizeError(state, fitsNr, isFootSize, rightSize, errorState) {
    if (isFootSize) {
        if (rightSize) {
            state[fitsNr].fitsErrors.footSizeRight = errorState
        } else {
            state[fitsNr].fitsErrors.footSizeLeft = errorState
        }
    } else {
        if (rightSize) {
            state[fitsNr].fitsErrors.soleSizeRight = errorState
        } else {
            state[fitsNr].fitsErrors.soleSizeLeft = errorState
        }
    }
}

/**
 * check size
 *
 * @param {Object} state - current state.
 * @param {Number} fitsNr - fitsNumber
 * @param {Object} value - given value.
 * @param {Boolean} isFootSize - true if footsize is filled.
 * @param {Boolean} rightSize - true if rightSize is entered.
 */
export function validateSize(state, fitsNr, value, isFootSize, rightSize) {
    if (value === '') return

    const fitsSize = dataProvider.fitsSpecification.fitsSize
    const fits = state[fitsNr].fitsData

    if (value % 1 !== 0 && (isFootSize === false || fits.footSizeLeft === fits.soleSizeLeft || fits.footSizeRight === fits.soleSizeRight)) {
        setSizeError(state, fitsNr, false, rightSize, true)
        state[fitsNr].fitsErrors.message.push('Error 704: Zoolmaat moet een geheel getal zijn voor FITS')
        return
    }

    let left = 0
    let right = 0

    if (isFootSize) {
        if (rightSize) {
            right = value - fits.soleSizeRight
        } else {
            left = value - fits.soleSizeLeft
        }
    } else {
        if (rightSize) {
            right = value - fits.footSizeRight
        } else {
            left = value - fits.footSizeLeft
        }
    }

    if (!isFootSize && ((left <= -2 || left >= 2) || (right <= -2 || right >= 2))) {
        setSizeError(state, fitsNr, isFootSize, rightSize, true)
        state[fitsNr].fitsErrors.message.push('Error 706: Voetmaat verschil mag niet groter dan 1 maat zijn')
        return
    }

    if (value > 10 && (value < fitsSize.min || value > fitsSize.max)) {
        setSizeError(state, fitsNr, isFootSize, rightSize, true)
        state[fitsNr].fitsErrors.message.push(`Error 705: Vul een ${isFootSize ? 'voetmaat' : 'zoolmaat'} tussen ${fitsSize.min} en ${fitsSize.max} in.`)
        return
    }

    state[fitsNr].fitsErrors.message = state[fitsNr].fitsErrors.message.filter(e => e.substring(0, 9) !== 'Error 704')
    state[fitsNr].fitsErrors.message = state[fitsNr].fitsErrors.message.filter(e => e.substring(0, 9) !== 'Error 705')
    state[fitsNr].fitsErrors.message = state[fitsNr].fitsErrors.message.filter(e => e.substring(0, 9) !== 'Error 706')
    setSizeError(state, fitsNr, isFootSize, rightSize, false)
}

/**
 *
 * @param {*} state
 * @param {*} fitsNr
 */
export function availableSizes(state, fitsNr) {
    const unavailable = dataProvider.fitsSpecification.unavailable

    const fits = state[fitsNr].fitsData
    if (fits.model !== '' && fits.upperMaterial !== '') {
        state[fitsNr].fitsErrors.message = state[fitsNr].fitsErrors.message.filter(e => e.substring(0, 9) !== 'Error 708')
        const unavailableSizes = unavailable.get(fits.model.key).get(fits.upperMaterial) || []

        if (unavailableSizes.length !== 0 && (unavailableSizes.includes(parseInt(fits.footSizeLeft)) || unavailableSizes.includes(parseInt(fits.footSizeRight)))) {
            state[fitsNr].fitsErrors.message.push('Error 709: Model, bovenwerk en maat combinatie is niet beschikbaar')
            state[fitsNr].fitsErrors.model = true
            state[fitsNr].fitsErrors.upperMaterial = true
        } else {
            state[fitsNr].fitsErrors.message = state[fitsNr].fitsErrors.message.filter(e => e.substring(0, 9) !== 'Error 709')
            state[fitsNr].fitsErrors.model = false
            state[fitsNr].fitsErrors.upperMaterial = false
        }
    } else {
        state[fitsNr].fitsErrors.message.push('Error 708: Vul eerst model of afdekmateriaal in')
    }
}

/**
 *
 */
export function validateSoleThickness(state, fitsNr, value) {
    if (value < 2 || value > 6) {
        state[fitsNr].fitsErrors.message.push('Error 707: Zooldikte mag niet kleiner zijn dan 2mm en groter dan 6mm')
    } else {
        state[fitsNr].fitsErrors.message = state[fitsNr].fitsErrors.message.filter(e => e.substring(0, 9) !== 'Error 707')
    }
}

/**
 * Validate all fields if they are not empty and contain no errors.
 *
 * @param {Object} state - current state.
 * @param {Number} fitsNr - fitsNumber.
 */
export function validateAllFields(state, fitsNr) {
    const fitsError = state[fitsNr].fitsErrors
    let validate = true
    if (
        fitsError.model ||
        fitsError.upperMaterial ||
        fitsError.coverMaterial ||
        fitsError.block ||
        fitsError.outsole ||
        fitsError.finishingShape ||
        fitsError.upperPosition ||
        fitsError.footSizeLeft ||
        fitsError.footSizeRight ||
        fitsError.leftSoleWidth ||
        fitsError.rightSoleWidth ||
        fitsError.leftSoleWidthMessage ||
        fitsError.rightSoleWidthMessage ||
        fitsError.totalLeftSoleThickness ||
        fitsError.totalRightSoleThickness ||
        checkEmptyFields(state, fitsNr)
    ) validate = false

    state[fitsNr].fitsAccordion.validate = validate
}

/**
 * Check if the fields of fitsSpecification are empty.
 *
 * @param {Object} state - redux state object
 * @returns {boolean} true
 */
function checkEmptyFields(state, fitsNr) {
    const fits = state[fitsNr].fitsData

    state[fitsNr].fitsErrors.model = !fits.model
    state[fitsNr].fitsErrors.upperMaterial = !fits.upperMaterial
    state[fitsNr].fitsErrors.coverMaterial = !fits.coverMaterial
    state[fitsNr].fitsErrors.block = !fits.block
    state[fitsNr].fitsErrors.outsole = !fits.outsole
    state[fitsNr].fitsErrors.finishingShape = !fits.finishingShape
    state[fitsNr].fitsErrors.upperPosition = !fits.upperPosition
    state[fitsNr].fitsErrors.footSizeLeft = !fits.footSizeLeft
    state[fitsNr].fitsErrors.footSizeRight = !fits.footSizeRight
    state[fitsNr].fitsErrors.soleSizeLeft = !fits.soleSizeLeft
    state[fitsNr].fitsErrors.soleSizeRight = !fits.soleSizeRight
    state[fitsNr].fitsErrors.leftSoleWidth = !fits.leftSoleWidth
    state[fitsNr].fitsErrors.rightSoleWidth = !fits.rightSoleWidth

    return !fits.model ||
        !fits.upperMaterial ||
        !fits.coverMaterial ||
        !fits.block ||
        !fits.outsole ||
        !fits.finishingShape ||
        !fits.upperPosition ||
        !fits.footSizeLeft ||
        !fits.footSizeRight ||
        !fits.soleSizeLeft ||
        !fits.soleSizeRight ||
        !fits.leftSoleWidth ||
        !fits.rightSoleWidth
}
