// import UserService from './userService'

import * as vcwDiagnosis from './data/vcw/diagnosis'

import * as vcwFitsSpecification from './data/vcw/fitsSpecification'

import * as vcwSoleAddition from './data/vcw/soleAddition'
import * as vcwSoleResult from './data/vcw/soleResult'

import * as kievitOrthoSoleSpecification from './data/kievit-ortho/soleSpecification'
import * as vcwSoleSpecification from './data/vcw/soleSpecification'

class DataProvider {
    /**
     * Constructor function
     */
    constructor () {
        this.folder = 'vcw'
        this.locations =[{ id: -1, title: '(.VCW) Sturen pt via Zoollogistiek' }]
    }

    /**
     *
     */
    getLocations() {
        return this.locations
    }

    /**
     * 
     * @param {*} locationMap 
     */
    setLocation(locationMap) {
        this.locations = locationMap
    }
    /**
     * Get the diagnosis
     *
     * @returns {Promise} a pallete
     */
    get diagnosis () {
        let diagnosis = null
        switch (this.folder) {
        default:
            diagnosis = vcwDiagnosis
            break
        }
        return diagnosis
    }

    /**
     * Get the diagnosis
     *
     * @returns {Promise} a pallete
     */
    get fitsSpecification () {
        let fitsSpecification = null
        switch (this.folder) {
        default:
            fitsSpecification = vcwFitsSpecification
            break
        }
        return fitsSpecification
    }

    /**
     * Get the diagnosis
     *
     * @returns {Promise} a pallete
     */
    get soleAddition () {
        let soleAddition = null
        switch (this.folder) {
        default:
            soleAddition = vcwSoleAddition
            break
        }
        return soleAddition
    }

    /**
     * Get the diagnosis
     *
     * @returns {Promise} a pallete
     */
    get soleResult () {
        let soleResult = null
        switch (this.folder) {
        default:
            soleResult = vcwSoleResult
        }
        return soleResult
    }

    /**
     * Get the diagnosis
     *
     * @returns a pallete
     */
    get soleSpecification () {
        let soleSpecification = null
        switch (this.folder) {
        case 'Kievit Orthopedie':
            soleSpecification = kievitOrthoSoleSpecification
            break
        default:
            soleSpecification = vcwSoleSpecification
        }
        return soleSpecification
    }

    /**
     *
     * @param {*} value
     */
    setFolder (value) {
        this.folder = value
    }

    /**
     *
     * @returns
     */
    getFolder () {
        return this.folder
    }
}

 
export default new DataProvider()
