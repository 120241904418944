export const initialState = {
    additionData: {
        addition: [
            {
                name: '',
                location: '',
                forSoles: []
            }
        ],
        narrowGrinding: false
    },
    additionAccordion: {
        accordion: false,
        validate: true,
        hasBeenOpened: false
    },
    additionErrors: {
        display: false,
        message: [],
        addition: [
            false,
            false,
            false,
            false,
            false
        ]
    }
}
