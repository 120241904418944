import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './pages/index'
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter } from 'react-router-dom'
import UserService from './lib/userService'
import { Typography } from '@mui/material'

/**
 * render object
 */
const render = () => {
    const container = document.getElementById('root')
    const root = createRoot(container)
    root.render(<React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>)
}

/**
 * render object
 */
const renderError = (errorMSg) => {
    const container = document.getElementById('root')
    const root = createRoot(container)
    root.render(<React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Typography>Something went wrong {errorMSg}</Typography>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>)
}

const currentUrl = window.location.href
if (currentUrl.includes('test.sim.podo-it.nl/')) {
    window.location.href = 'https://slim-modelleren.nl'
} else {
    UserService.initKeycloak(render, renderError)
}
// render()
