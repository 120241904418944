import { fittingData } from '../../../lib/fittingData'

export const initialState = {
    soleData: {
        millingSize: '30',
        block: { key: 'Combi 40 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
        coverMaterial: 'Su\xE8de grijs',
        coverLevel: 'Volledig afdekken',
        footSizeLeft: '',
        footSizeRight: '',
        soleSizeLeft: '',
        soleSizeRight: '',
        groundSole: {
            name: 'PLT Elegant',
            min: 36,
            max: 47,
            sizeSystem: 'EU',
            fittingData: fittingData['PLT Elegant']
        },
        broadenedFront: '',
        soleCompleteness: 'Hele zool',
        soleType: 'podozool',
        displayUKsize: false,
        displayRightSole: false,
        leftSoleThickness: 0.7,
        rightSoleThickness: 0.7,
        totalLeftSoleThickness: 2,
        totalRightSoleThickness: 2,
        hypoallergenicGlue: false
    },
    soleAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    soleErrors: {
        display: false,
        message: [],
        millingSize: false,
        block: false,
        coverMaterial: false,
        coverLevel: false,
        footSizeLeft: false,
        footSizeRight: false,
        soleSizeLeft: false,
        soleSizeRight: false,
        groundSole: false,
        broadenedFront: false,
        soleThickness: false
    }
}
