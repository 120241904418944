import React, { useEffect, useState } from 'react'
import useStyles from './style'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Select,
    MenuItem,
    ListItemText,
    TextField,
    Box,
    Chip,
    Typography,
    Button,
    Autocomplete
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Add, ExpandMore, Remove } from '@mui/icons-material'
import { additionAccordion, additionData } from '../../store/reducers/soleAddition'
import { deepCopy } from '../../lib/util'
import dataProvider from '../../lib/dataProvider'

const ListVcwAdditions = dataProvider.soleAddition.ListVcwAdditions

const SoleAddition = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const tab = useSelector((state) => (state.soleAddition.additionAccordion))
    const addition = useSelector((state) => state.soleAddition.additionData)
    const error = useSelector((state) => state.soleAddition.additionErrors)
    const diagnosis = useSelector((state) => state.diagnosis.diagnosisData)
    const numberOfSoles = diagnosis.numberOfSoles
    const soleSpecification = useSelector((state) => state.soleSpecification)
    const general = useSelector((state) => state.general)

    const [soleSpec, setSoleSpec] = useState(soleSpecification)

    useEffect(() => {
        // Check if coverlevel is updated.
        soleSpecification.forEach((element, i) => {
            // if this version of coverlevel is out of date, update local state.
            if (element.soleData.coverLevel !== undefined && soleSpec[i].soleData.coverLevel !== element.soleData.coverLevel) {
                // update local state.
                setSoleSpec(soleSpecification)
            }
        })
    }, [soleSpecification])

    /**
     * Only runs when coverlevel is updated.
     */
    useEffect(() => {
        addition.addition.forEach((element, i) => {
            dispatch(additionData({ key: 'soleLocation', number: i, value: element.location, numberOfSoles, soleSpecification }))
        })
    }, [soleSpec])
    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(additionAccordion({ key: 'accordion', value: { accordion: !tab.accordion, general } }))
    }

    /**
    * Change number of VCW additions
    */
    function addAddition () {
        const temp = deepCopy(addition.addition)
        temp.push({
            name: '',
            location: '',
            forSoles: []
        })
        dispatch(additionData({ key: 'addAddition', value: temp }))
    }

    /**
    * Change number of VCW additions
    */
    function removeAddition () {
        const temp = deepCopy(addition.addition)
        const num = temp.length - 1
        temp.pop()
        if (num >= 0) {
            dispatch(additionData({ key: 'removeAddition', value: temp }))
        }
    }

    /**
     * Update the location field
     */
    function updateLocationFoot (value, number) {
        dispatch(additionData({ key: 'soleLocation', number, value, numberOfSoles, soleSpecification }))
    }

    /**
     * Updates the chosen addition.
     */
    function onsoleAdditionUpdated (value, number) {
        dispatch(additionData({ key: 'soleAddition', number, value, numberOfSoles, soleSpecification }))
    }

    /**
     * Updates for which soles the addition is.
     */
    function addSole (value, number) {
        dispatch(additionData({ key: 'addSole', value, number, numberOfSoles, soleSpecification }))
    }

    /**
     * Display the number of additions
     */
    function displayAdditions () {
        const jsx = []
        addition.addition.forEach((element, i) => {
            jsx.push(<Grid key={i} item xs={5}>
                <FormControl fullWidth error={error.addition[i]}>
                    <FormLabel>Aanvulling {i + 1}:</FormLabel>
                    <Autocomplete
                        name="additionVcw"
                        value={addition.addition[i].name}
                        onChange={(event, value) => onsoleAdditionUpdated(value, i)}
                        options={ListVcwAdditions}
                        className={classes.autoComplete}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                error={error.addition[i]} />
                        } />
                </FormControl>
            </ Grid>,
            <Grid key={i + 'locatie'} item xs={3}>
                <FormControl fullWidth error={error.addition[i]}>
                    <FormLabel>Aanvulling locatie</FormLabel>
                    <Select
                        value={addition.addition[i].location}
                        onChange={(event) => updateLocationFoot(event.target.value, i)}
                        className={classes.autoComplete}
                    >
                        <MenuItem value={'left'}>Links</MenuItem>
                        <MenuItem value={'right'}>Rechts</MenuItem>
                        <MenuItem value={'both'}>Beide</MenuItem>
                    </Select>
                </FormControl>
            </Grid>,
            <Grid key={i + 'aanvulling'} item xs={4}>
                <FormControl fullWidth error={error.addition[i]}>
                    <FormLabel>Zool aanvulling</FormLabel>
                    <Select
                        value={addition.addition[i].forSoles}
                        multiple
                        onChange={(event) => addSole(event.target.value, i)}
                        className={classes.autoComplete}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip className={classes.chip} key={value} label={'Zool ' + (parseInt(value) + 1)} />
                                ))}
                            </Box>
                        )}
                    >
                        {renderSoleOptions(i)}
                    </Select>
                </FormControl>
            </Grid>
            )
        })
        return jsx
    }

    /**
     * Show as many sole options as selected. For now it is not needed since by default we will only order one.
     * @return {JSX} - a map of JSX solespecification.
     */
    function renderSoleOptions (number) {
        const soleResTabs = []
        for (let i = 0; i < diagnosis.numberOfSoles; i++) {
            soleResTabs.push(
                <MenuItem key={i} value={i}>
                    <Checkbox checked={addition.addition[number].forSoles.indexOf(i) > -1} />
                    <ListItemText primary={`Zool ${i + 1}`} />
                </MenuItem>
            )
        }
        return soleResTabs
    }

    if (soleSpecification[0].soleData.block !== '3D Sport Printzool') {
        return (
            <Accordion expanded={tab.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={toggleAccordion}
                    className={
                        !tab.accordion
                            ? tab.hasBeenOpened
                                ? tab.validate
                                    ? classes.accordionSuccess
                                    : classes.accordionError
                                : null
                            : null
                    }
                >
                    <Typography
                        className={
                            !tab.accordion
                                ? tab.hasBeenOpened
                                    ? tab.validate
                                        ? classes.titleSuccess
                                        : classes.titleError
                                    : classes.title
                                : classes.title
                        } >Aanvullingen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={4}>
                        <Grid item xs={12}><FormLabel style={{ fontSize: 18 }}>Opmerkingen VCW</FormLabel></Grid>

                        {displayAdditions()}
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item>
                                    <Button color='secondary' onClick={() => addAddition()} variant="outlined" startIcon={<Add />}>
                                    aanvulling
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button color='secondary' onClick={() => removeAddition()} variant="outlined" startIcon={<Remove />}>
                                    aanvulling
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default withStyles(useStyles)(SoleAddition)
