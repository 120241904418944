export const stanceDifferences = [
    {
        name: 'Geen',
        ASC: '',
        MFW: '',
        LFW: '',
        SA25: '',
        SA1: ''
    },
    {
        name: 'Artrogene beperking BSG',
        ASC: '5',
        MFW: '',
        LFW: '',
        SA25: '',
        SA1: ''
    },
    {
        name: 'Structureel plantair geflecteerde eerste straal',
        ASC: '',
        MFW: '',
        LFW: '',
        SA25: 'invullen naar eigen inzicht tussen 0-10',
        SA1: ''
    },
    {
        name: 'Structureel dorsaal geflecteerde eerste straal',
        ASC: '',
        MFW: '',
        LFW: '',
        SA25: '',
        SA1: 'Invullen naar eigen inzicht tussen 0-10'
    },

    {
        name: 'Structurele voorvoet inversie',
        ASC: '',
        MFW: 'Invullen naar eigen inzicht tussen 0-10',
        LFW: '',
        SA25: '',
        SA1: ''
    },
    {
        name: 'Structurele voorvoet eversie',
        ASC: '',
        MFW: '',
        LFW: 'Invullen naar eigen inzicht tussen 0-10',
        SA25: '',
        SA1: ''
    },
    {
        name: 'Structureel plantair geflecteerde eerste straal & voorvoet eversie stand',
        ASC: '',
        MFW: '',
        LFW: 'Invullen naar eigen inzicht tussen 0-10',
        SA25: '',
        SA1: ''
    },
    {
        name: 'Structureel dorsaal geflecteerde eerste straal & voorvoet inversie stand',
        ASC: '',
        MFW: 'invullen naar eigen inzicht tussen 0-10',
        LFW: '',
        SA25: '',
        SA1: ''
    }
]
