export const millSizes = [
    40
]

export const blockChoices = [
    { key: '3D PU Blok', soleThickness: 1, millingSize: 38, coverMaterial: 'Su\xE8de grijs' },
    {
        key: '3D Sport Printzool',
        soleThickness: 0.5,
        millingSize: 40,
        coverMaterial: '3mm 3D sport',
        suggestion: {
            ASC: '2',
            RCTB: '5',
            SA15: '3',
            CV: '10'
        }
    },
    { key: 'Combi 30 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Combi 40 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Combi Sport 35 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA 30 sh - 38mm hoog', soleThickness: 0.7, millingSize: 38, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA 40 sh - 38mm hoog', soleThickness: 0.7, millingSize: 38, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA 50 sh - 38mm hoog', soleThickness: 0.7, millingSize: 38, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA 50 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA 60 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'EVA Sport 50-30-65 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Supraflex 20 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Supraflex 30 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Supraflex 40 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' },
    { key: 'Supraflex 50 sh', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' }
]

export const coverMaterials = [
    '-',
    '2mm 3D sport',
    '3mm 3D sport',
    '4mm 3D sport'
]

export const coverLevels = {
    'Volledig afdekken': { CoverLeft: true, CoverRight: true }
}

export const coverLevelsCompletePair = {
    'Volledig afdekken': { CoverLeft: true, CoverRight: true }
}

export const soleCompleteness = [
    'Hele zool',
    'Halve zool',
    'Drie kwart zool'
]

// Doubles please after each other. like PLT Elegant
export const groundSolePatternTypes = [
    {
        name: '3D Sport Printzool',
        min: 36,
        max: 50,
        publock: false,
        sizeSystem: 'EU'
    }
]
