import React, { useEffect, useState } from 'react'
import {
    Grid,
    Card,
    Typography,
    Button,
    Table,
    TableRow,
    TableCell,
    TableBody,
    LinearProgress,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { podoITexport } from '../../lib/exportService'
import { unityExport } from '../../lib/unityService'
import { ExpandMore } from '@mui/icons-material'
import useStyles from './style'
import moment from 'moment'
import api from '../../lib/api'
import SnackbarComponentSuccess from '../../components/SnackbarComponentSuccess'
import { withStyles } from '@mui/styles'
import { changeAllValid, changeErrorDialog } from '../../store/reducers/other'
import BlobModelViewer from '../../components/BlobModelViewer'
import { base64ToBlob } from '../../lib/util'
import { objToPPC } from '../../lib/ppcService'
import { rhinoExport } from '../../lib/rhinoService'
import { pltConverter } from '../../lib/pltExport'
import UserService from '../../lib/userService'

const Overview = ({ classes }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [url1, setUrl1] = useState(null)
    const [url2, setUrl2] = useState(null)

    const [showResearcherDownload, setResearcherDownload] = useState(false)
    const [unityResult, setUnityResult] = useState(null)
    const [ppcResult, setPpcResult] = useState(null)
    const [displaySnackbar, setDisplaySnackbar] = useState(false)
    const [sendToPodoIT, setSendToPodoIT] = useState(true)
    const [sendToPLT, setSendToPLT] = useState(true)

    const orderType = useSelector((state) => state.general.orderType)
    const diagnosis = useSelector((state) => state.diagnosis.diagnosisData)
    const patient = useSelector((state) => state.general.patientData)
    const soles = useSelector((state) => state.soleSpecification)
    const fits = useSelector((state) => state.fitsSpecification)
    const soleRes = useSelector((state) => state.soleResult)

    const fullState = useSelector((state) => state)

    useHotkeys('shift+d', () => showFunctions())

    useEffect(() => {
        downloadJsonUnity()
    }, [])

    /**
     * Enable special features
     */
    function showFunctions () {
        setResearcherDownload(true)
    }

    /**
     * Generate a filename.
     *
     * @return {String} - the generatedd filename
     */
    function generateFileName () {
        const today = moment().format('YYYY-MM-DD')
        const hrs = moment().format('hh-mm-ss')
        return `${today}_${hrs}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}.json`
    }

    /**
     * Download all files.
     */
    async function downloadJson () {
        const generalModel = podoITexport(fullState)
        const today = moment().format('YYYY-MM-DD')
        const generalButton = document.createElement('a')
        generalButton.download = `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}-general.json`
        generalButton.href = `data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(generalModel, null, '\t')
        )}`
        generalButton.click()

        const rhinoModel = rhinoExport(fullState)
        const rhinoButton = document.createElement('a')
        rhinoButton.download = `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}-rhino.json`
        rhinoButton.href = `data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(rhinoModel, null, '\t')
        )}`
        rhinoButton.click()

        const ppcBlob = await objToPPC(unityResult, patient, soles, generalModel)
        const link = document.createElement('a')
        link.href = URL.createObjectURL(ppcBlob)
        link.download = `${today}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}.ppc`
        link.click()
    }

    /**
     * Download unity file.
     */
    function downloadJsonUnity () {
        const unityModel = { soleInput: unityExport(fullState) }
        api.postInsole(unityModel, onSuccessUnity, onErrorUnity)
    }

    /**
     * Return to homepage
     */
    function returnToStart () {
        window.location.replace(api.accountLinkUrl)
    }

    /**
     * Send order
     */
    async function sendOrder () {
        const generalModel = podoITexport(fullState)
        const pltExport = pltConverter(fullState)

        if(orderType === 'insole'){
            delete pltExport.fits_result
        } else {
            delete pltExport.sole_result
        }
        const ppcBlob = await objToPPC(unityResult, patient, soles, generalModel)
        setPpcResult(ppcBlob)

        if (sendToPodoIT) {
            api.placeOrder(generalModel, onSuccess, onError)
        }

        if (sendToPLT && UserService.hasRole('PLT-tester')) {
            api.placePltOrder(pltExport, ppcBlob, onSuccess, onPLTError)
        } else {
            const rhinoModel = rhinoExport(fullState)

            const today = moment().format('YYYY-MM-DD')
            const hrs = moment().format('hh-mm-ss')
        
            const fileName = `${today}_${hrs}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}`
            const subFolderName = `${fileName}`
            api.uploadToSharepoint(rhinoModel, fileName, subFolderName, '-rhino.json', console.log(), console.log())
            api.uploadToSharepoint(generalModel, fileName, subFolderName, '-general.json', console.log(), console.log())
            api.uploadToSharepoint(ppcBlob, fileName, subFolderName, '.ppc', console.log(), console.log())
        }
    }

    /**
     * Display success snackbar
     *
     * @param {Object} param - succes param
     */
    function onSuccess () {
        setDisplaySnackbar(true)
        setSendToPodoIT(false)
        setSendToPLT(false)
    }

    /**
     * Display success snackbar
     *
     * @param {Object} result - succes param
     */
    async function onSuccessUnity (result) {
        setUnityResult(result)
        for (let index = 0; index < result.Results.length; index++) {
            const blob = base64ToBlob(result.Results[index].Sole, 'application/octet-stream')

            if (index % 2 !== 0) {
                setUrl2(URL.createObjectURL(blob))
            } else {
                setUrl1(URL.createObjectURL(blob))
            }
        }
    }

    /**
     * callback function called when the
     * snackbar should close
     */
    function onSnackbarClose () {
        setDisplaySnackbar(false)
    }

    /**
     * Display error snackbar
     *
     * @param {Object} param - error param.
     */
    function onError (param) {    
        const generalModel = podoITexport(fullState)
        generalModel.error = param
    
        const today = moment().format('YYYY-MM-DD')
        const hrs = moment().format('hh-mm-ss')
    
        const fileName = `${today}_${hrs}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}-general`
        const subFolderName = `PodoIT - Errors`
        api.uploadToSharepoint(generalModel, fileName, subFolderName, '.json', console.log(), console.log(), 'ProblemenExport')
    }

    /**
     * Display error snackbar
     *
     * @param {Object} param - error param.
     */
    function onPLTError (param) {
        const generalModel = podoITexport(fullState)
        generalModel.error = param
    
        const today = moment().format('YYYY-MM-DD')
        const hrs = moment().format('hh-mm-ss')
    
        const fileName = `${today}_${hrs}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}`
        const subFolderName = `PLT - Errors/${fileName}`
        api.uploadToSharepoint(generalModel, fileName, subFolderName, '.json', console.log(), console.log(), 'ProblemenExport')
        api.uploadToSharepoint(ppcResult, fileName, subFolderName, '.ppc', console.log(), console.log(), 'ProblemenExport')
    }

    /**
     * Display error snackbar
     *
     * @param {Object} param - error param.
     */
    function onErrorUnity (param) {
        const generalModel = podoITexport(fullState)
        generalModel.error = param

        const today = moment().format('YYYY-MM-DD')
        const hrs = moment().format('hh-mm-ss')

        const fileName = `${today}_${hrs}_${patient.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.number.replace(' ', '')}-general`
        const subFolderName = `Unity - Errors`
        api.uploadToSharepoint(generalModel, fileName, subFolderName, '.json', console.log(), console.log(), 'ProblemenExport')
    }

    /**
     *
     */
    function backButton () {
        navigate(-1)
        dispatch(changeErrorDialog(false))
        dispatch(changeAllValid(false))
    }

    /**
     * Display buttons to continue with
     *
     * @param {Object} hasDownloadJson - boolean
     * @return {JSX} - button bar with objects.
     */
    function ButtonBar ({ hasDownloadJson }) {
        return <div>
            <Grid container className={classes.buttonBar} justifyContent="space-between">
                <Grid xs={2} container direction="column">
                    <Button color='warning' variant="contained"
                        onClick={backButton}>
                        Terug
                    </Button>
                </Grid>
                <Grid xs={2} container direction="column">
                    <Button
                        variant="contained"
                        onClick={returnToStart}
                        color='success'
                    >
                        Nieuwe klant
                    </Button>
                </Grid>
                <Grid xs={2} container direction="column">
                    <Button
                        variant="contained"
                        onClick={sendOrder}
                        color='primary'
                        disabled={!sendToPodoIT && !sendToPLT}
                    >
                        Verstuur
                    </Button>
                </Grid>
            </Grid>

            {hasDownloadJson &&
                <Grid container className={classes.buttonBar} justifyContent="space-between">
                    <Grid key={1} xs={2} container direction="column">
                        <Button variant="contained" color='primary'
                            onClick={downloadJson}>
                            Researcher
                        </Button>
                    </Grid>
                </Grid>
            }
        </div>
    }

    /**
     * Get a sole view.
     *
     * @returns {JSX} display all the soles.
     */
    function displaySoles () {
        const displaySoles = []
        for (let i = 0; i < diagnosis.numberOfSoles; i++) {
            displaySoles.push(
                <Accordion defaultExpanded={true}>             
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Zoolspecificaties - {i + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table key={i} className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Blokkeuze</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{soles[i].soleData.block.key ? soles[i].soleData.block.key : ''}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Hoogte freesblok</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {soles[i].soleData.millingSize ? soles[i].soleData.millingSize : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Grondzoolpatroontype</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{soles[i].soleData.groundSole.name}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Afdekmateriaal</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {soles[i].soleData.coverMaterial ? soles[i].soleData.coverMaterial : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Afdekniveau</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {soles[i].soleData.coverLevel ? soles[i].soleData.coverLevel : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Voetmaat links/ rechts</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {soles[i].soleData.footSizeLeft ? soles[i].soleData.footSizeLeft : ''}
                                            / {soles[i].soleData.footSizeRight ? soles[i].soleData.footSizeRight : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Zoolmaat links/ rechts</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {soles[i].soleData.soleSizeLeft ? soles[i].soleData.soleSizeLeft : ''}
                                            / {soles[i].soleData.soleSizeRight ? soles[i].soleData.soleSizeRight : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Extra zooldikte links/ rechts</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {soles[i].soleData.leftSoleThickness ? soles[i].soleData.leftSoleThickness : '0.0'}
                                            / {soles[i].soleData.rightSoleThickness ? soles[i].soleData.rightSoleThickness : '0.0'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Totale zooldikte links/ rechts</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {soles[i].soleData.totalLeftSoleThickness ? soles[i].soleData.totalLeftSoleThickness : '0'}
                                            / {soles[i].soleData.totalRightSoleThickness ? soles[i].soleData.totalRightSoleThickness : '0'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Verbreding voorblad</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {soles[i].soleData.broadenedFront ? soles[i].soleData.broadenedFront : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Soort zool</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {soles[i].soleData.soleCompleteness ? soles[i].soleData.soleCompleteness : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Zoolkeuze</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{soles[i].soleData.soleType}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
            )
        }

        return displaySoles
    }

    /**
     * Get a FITS overview of all the data.
     *
     * @returns {JSX} display all the soles.
     */
    function displayFits () {
        const displayFits = []
        for (let i = 0; i < diagnosis.numberOfSoles; i++) {
            displayFits.push(
                <Accordion defaultExpanded={true}>             
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>FITS specificaties - {i + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table key={i} className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Model</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{fits[i].fitsData.model.displayValue ? fits[i].fitsData.model.displayValue : ''}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Hoogte Freesblok</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {fits[i].fitsData.upperMaterial ? fits[i].fitsData.upperMaterial : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Afdekmateriaal</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {fits[i].fitsData.coverMaterial ? fits[i].fitsData.coverMaterial : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Blokkeuze</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {fits[i].fitsData.block ? fits[i].fitsData.block : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Loopzool</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {fits[i].fitsData.outsole ? fits[i].fitsData.outsole : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Afwerkingsvorm</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {fits[i].fitsData.finishingShape ? fits[i].fitsData.finishingShape : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Voetmaat links/ rechts</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {fits[i].fitsData.footSizeLeft ? fits[i].fitsData.footSizeLeft : ''}
                                            / {fits[i].fitsData.footSizeRight ? fits[i].fitsData.footSizeRight : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Wijdtemaat links/ rechts</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {fits[i].fitsData.leftSoleWidth ? fits[i].fitsData.leftSoleWidth : '0'}
                                            / {fits[i].fitsData.rightSoleWidth ? fits[i].fitsData.rightSoleWidth : '0'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Wijdte links/ rechts</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {fits[i].fitsData.leftSoleWidthMessage ? fits[i].fitsData.leftSoleWidthMessage : ''}
                                            / {fits[i].fitsData.rightSoleWidthMessage ? fits[i].fitsData.rightSoleWidthMessage : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Externe hielheffing links/ rechts</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {soleRes[i].soleResultData.left.externalHeelLift ? soleRes[i].soleResultData.left.externalHeelLift : ''}
                                            / {soleRes[i].soleResultData.right.externalHeelLift ? soleRes[i].soleResultData.right.externalHeelLift : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Extra Element</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {fits[i].fitsData.extraElement.includes('30sh 3mm EVA - zwart') ? '30sh 3mm EVA - zwart' : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Zoolkeuze</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{fits[i].fitsData.soleType}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
            )
        }

        return displayFits
    }

    return (
        unityResult === null
            ? <div>          
                <Grid container className={classes.buttonBar} justifyContent="space-between">
                    <Grid xs={2} container direction="column">
                        <Button color='warning' variant="contained"
                            onClick={backButton}>
                            Terug
                        </Button>
                    </Grid>
                    <Grid xs={2} container direction="column">
                    </Grid>
                    <Grid xs={2} container direction="column">
                    
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '60vh',
                    }}
                >
                    {/* LinearProgress Bar */}
                    <Box sx={{ width: '80%', marginTop: 2 }}>
                        <LinearProgress />
                    </Box>
                </Box>
            </div>
            : <div>
                <ButtonBar hasDownloadJson={showResearcherDownload} />
                {unityResult
                    ? <BlobModelViewer objUrl1={url1} objUrl2={url2} />
                    : null
                }

                <Accordion>             
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Algemene Patientgegevens</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Ingezonden freesfile</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{generateFileName()}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Patiëntnaam</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{patient ? patient.lastName : ''}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>

                                        <Typography>Patiëntnummer</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{patient ? patient.number : ''}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>

                                        <Typography>Geboortedatum</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{patient ? moment(patient.birthdate).format('DD/MM/YYYY') : ''}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Geslacht</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{patient.gender === 'male' ? 'Man' : 'Vrouw'}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Aanmeetlocatie</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {patient.fittingLocation
                                                ? patient.fittingLocation.title
                                                : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography>Ontvangstlocatie</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {patient.receiveLocation
                                                ? patient.receiveLocation.title
                                                : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {orderType === 'fits'
                                    ? null
                                    : <TableRow>
                                        <TableCell>
                                            <Typography>Spoedbestelling</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {patient.emergencyOrder ? 'Ja' : 'Nee'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                {orderType === 'fits' ? displayFits() : displaySoles()}
                <Card variant="outlined"></Card>
                <div className={classes.bottomPageSpacer}>
                    <ButtonBar hasDownloadJson={showResearcherDownload} />
                </div>

                <SnackbarComponentSuccess display={displaySnackbar}
                    message={'Successvol verstuurd naar Podo-IT en PLT'}
                    onClose={onSnackbarClose}
                />
            </div>
    )
}

export default withStyles(useStyles)(Overview)