import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './style'
import { withStyles } from '@mui/styles'
import { closeGeneral } from '../../store/reducers/general'
import { closeDiagnosis } from '../../store/reducers/diagnosis'
import { closeSoles } from '../../store/reducers/soleSpecification'
import { closeSoleResult } from '../../store/reducers/soleResult'
import { closeAddition } from '../../store/reducers/soleAddition'
import { changeAllValid, changeErrorDialog } from '../../store/reducers/other'
import { closeFits } from '../../store/reducers/fitsSpecification'
import { LoadingButton } from '@mui/lab'
import { Receipt } from '@mui/icons-material'

const NavigationButtons = ({ classes, validateTabsCallback }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const general = useSelector(state => state.general)
    const generalAccordion = general.generalAccordion
    const orderType = general.orderType
    const { diagnosisData, diagnosisAccordion } = useSelector((state) => state.diagnosis)
    const soleSpecificationTabs = useSelector(state => state.soleSpecification)
    const fitsSpecificationTabs = useSelector(state => state.fitsSpecification)
    const { additionAccordion } = useSelector(state => state.soleAddition)
    const soleResultTabs = useSelector(state => state.soleResult)
    const fitsResultTabs = useSelector(state => state.soleResult)
    const sole = soleSpecificationTabs
    const fits = fitsSpecificationTabs

    useEffect(() => {
        if (loading) {
            if (orderType !== 'fits') {
                validateSoleTabs()
            } else {
                validateFitsTabs()
            }
        }
    }, [generalAccordion, diagnosisAccordion, soleSpecificationTabs, fitsSpecificationTabs, soleResultTabs, additionAccordion])

    /**
     * Validate all the sole tabs after updating
     */
    function validateSoleTabs () {
        const soleSpecificationAccordions = soleSpecificationTabs
            .map(sst => sst.soleAccordion)
            .slice(0, diagnosisData.numberOfSoles)

        const areSoleSpecificationsValid = soleSpecificationAccordions.reduce(
            (previousValue, currentValue) => previousValue && currentValue.validate, true
        )

        const soleResultAccordions = soleResultTabs
            .map(sst => sst.soleResultAccordion)
            .slice(0, diagnosisData.numberOfSoles)

        const areSoleResultsValid = soleResultAccordions.reduce(
            (previousValue, currentValue) => !diagnosisData.symmetrical ? previousValue && (currentValue.left.validate && currentValue.right.validate) : previousValue && currentValue.left.validate, true
        )

        const isEverythingValid = generalAccordion.validate &&
        diagnosisAccordion.validate &&
        areSoleSpecificationsValid &&
        areSoleResultsValid &&
        additionAccordion.validate

        dispatch(changeAllValid(isEverythingValid))
        dispatch(changeErrorDialog(!isEverythingValid))
        setLoading(false)
    }

    /**
     * Validate all the fits tabs after updating
     */
    function validateFitsTabs () {
        const fitsSpecificationAccordions = fitsSpecificationTabs
            .map(sst => sst.fitsAccordion)
            .slice(0, diagnosisData.numberOfSoles)

        const areFitsSpecificationsValid = fitsSpecificationAccordions.reduce(
            (previousValue, currentValue) => previousValue && currentValue.validate, true
        )

        const soleResultAccordions = fitsResultTabs
            .map(sst => sst.soleResultAccordion)
            .slice(0, diagnosisData.numberOfSoles)

        const areSoleResultsValid = soleResultAccordions.reduce(
            (previousValue, currentValue) => !diagnosisData.symmetrical ? previousValue && (currentValue.left.validate && currentValue.right.validate) : previousValue && currentValue.left.validate, true
        )
        const isEverythingValid = generalAccordion.validate &&
        diagnosisAccordion.validate &&
        areFitsSpecificationsValid &&
        areSoleResultsValid

        dispatch(changeAllValid(isEverythingValid))
        dispatch(changeErrorDialog(!isEverythingValid))
        setLoading(false)
    }

    /**
     * Close all tabs
     */
    function closeTabs () {
        setLoading(true)
        dispatch(changeAllValid(false))
        dispatch(changeErrorDialog(false))
        if (orderType !== 'fits') {
            dispatch(closeGeneral())
            dispatch(closeDiagnosis())
            dispatch(closeSoles())
            dispatch(closeSoleResult({ diagnosis: diagnosisData, sole, orderType }))
            dispatch(closeAddition({ value: { general } }))
        } else {
            dispatch(closeGeneral())
            dispatch(closeDiagnosis())
            dispatch(closeFits())
            dispatch(closeSoleResult({ diagnosis: diagnosisData, fits, orderType }))
        }
    }

    return (
        <div className={classes.container}>
            <Button
                variant="contained"
                color='warning'
                className={classes.backButton}
                onClick={() => window.location.replace(`/${general.patientData.number}`)}
            >Terug</Button>
            <LoadingButton
                loading={loading}
                variant="contained"
                loadingPosition="start"
                color='secondary'
                onClick={() => closeTabs()}
                startIcon={<Receipt />}
            >
              Naar bestelgegevens
            </LoadingButton>
        </div>
    )
}

export default withStyles(useStyles)(NavigationButtons)
