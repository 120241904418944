import React from 'react'
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material'
import useStyles from './style'
import { withStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import {setBreakTheGlass } from '../../store/reducers/other'

const BreakTheGlassDialog = () => {
    const dispatch = useDispatch()
    
    const other = useSelector(state => state.other)
    const general = useSelector(state => state.general)

    /**
     * Close the dialog and continue to /order
     */
    function closeAndContinue () {
        dispatch(setBreakTheGlass(false))
        const generalButton = document.createElement('a')
        generalButton.href = other.backend.value + "/v3/patient/" + general.patientData.id + "/info"
        generalButton.click()
    }

    /**
     * Close the dialog and stay on page
     */
    function onErrorDialogClose () {
        dispatch(setBreakTheGlass(false))
        const generalButton = document.createElement('a')
        generalButton.href = "/"
        generalButton.click()
        
    }

    return (
        <Dialog
            open={other.breakTheGlass}
            onClose={onErrorDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                Geef de reden voor toegang aan in Podo-IT
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography>
                        Om deze patiënt in te zien moet er een reden worden geselecteerd. 
                        Open de Podo-IT patiëntenkaart om vanuit daar verder te gaan via het interventie tabblad.
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color='warning'
                    variant='contained'
                    onClick={onErrorDialogClose}>Nee, ga terug</Button>
                <Button
                    color='secondary'
                    variant='contained'
                    onClick={closeAndContinue} autoFocus>Ja, open Podo-IT</Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(useStyles)(BreakTheGlassDialog)
