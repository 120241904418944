import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

const otherSlice = createSlice({
    name: 'other',
    initialState,
    reducers: {
        changeVoetmax (state, action) {
            state.voetmax = action.payload
        },
        changeBackend (state, action) {
            state.backend = action.payload
        },
        changeDataProvider (state, action) {
            state.dataProvider = action.payload
        },
        changeAllValid (state, action) {
            state.allValid = action.payload
        },
        changeErrorDialog (state, action) {
            state.errorDialog = action.payload
        },
        setPreviousOrders (state, action) {
            state.previousOrders = action.payload
        }
    }
})

export const { changeVoetmax, changeBackend, setPreviousOrders, changeDataProvider, changeAllValid, changeErrorDialog } = otherSlice.actions
export default otherSlice.reducer
