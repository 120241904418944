import React, { useState } from 'react'
import {
    Button,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid, ListItemText, MenuItem,
    Select
} from '@mui/material'
import useStyles from './style'
import { useNavigate } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { changeErrorDialog } from '../../store/reducers/other'
import { generalAccordion } from '../../store/reducers/general'

const ValidationErrorDialog = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [suggestedValue, setSuggestedValue] = useState([])
    const [notAsSuggested, setNotAsSuggested] = useState(false)

    const other = useSelector(state => state.other)

    const notSuggestedList = [
        'Behandelaar verwacht betere resultaten met eigen keuze',
        'Eerdere behandeling geen succes',
        'Meerdere diagnoses',
        'Om problemen met de patiënt te voorkomen',
        'Patiënt wil zolen voor bepaalde schoenen',
        'Voetafawijking'
    ]

    /**
     * Close the dialog and continue to /order
     */
    function closeAndContinue () {
        dispatch(changeErrorDialog(false))
        navigate('/order')
    }

    /**
     * Close the dialog and stay on page
     */
    function onErrorDialogClose () {
        dispatch(changeErrorDialog(false))
        setNotAsSuggested(false)
        dispatch(generalAccordion({ key: 'accordion', value: true }))
    }

    return (
        <Dialog
            open={other.errorDialog}
            onClose={onErrorDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                {notAsSuggested ? 'Velden niet ingevuld als suggestie' : 'Niet volledig ingevuld'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {
                        !notAsSuggested &&
                        'In de roodgekleurde tabjes zijn velden niet of incorrect ingevuld. Wilt u verder gaan?'
                    }
                    {
                        notAsSuggested &&
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormLabel>Je wijkt af van één of meerdere suggesties voor de elementen.
                                        Wat is/zijn de redenen hiervoor?</FormLabel>
                                    <Select
                                        name="Oorzaak"
                                        id="oorzaak"
                                        multiple
                                        value={suggestedValue}
                                        onChange={event => setSuggestedValue(event.target.value)}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {notSuggestedList.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={suggestedValue.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color='warning'
                    variant='contained'
                    onClick={onErrorDialogClose}>Nee, ga terug</Button>
                <Button
                    color='secondary'
                    variant='contained'
                    disabled
                    onClick={closeAndContinue} autoFocus>Ja, ga verder</Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(useStyles)(ValidationErrorDialog)
