import { PltFormat } from "./slimfileformat/plt";
import { ReduxState } from "./slimfileformat/reduxState";

/**
 * 
 * @param {ReduxState} fullState
 * @returns {PltFormat}
 */
export function pltConverter(reduxState:ReduxState) : PltFormat {
    return  {
        general: {
            patient: {
                name: reduxState.general.patientData.lastName,
                id: reduxState.general.patientData.id
            },
            emergency_order: false, // Assuming a default value, adjust as needed
            therapist: reduxState.general.patientData.therapist.name,
            fitting_location: reduxState.general.patientData.fittingLocation.title, // Adjust based on available data
            receive_location: reduxState.general.patientData.receiveLocation.title, // Adjust based on available data
            complete_pair: reduxState.diagnosis.diagnosisData.completePair
        },
        send_date: new Date().toISOString(),
        sole_result:{
            sole_specification: {
                milling_size: reduxState.soleSpecification[0].soleData.millingSize,
                block: reduxState.soleSpecification[0].soleData.block.key,
                ground_sole: reduxState.soleSpecification[0].soleData.groundSole.name,
                sole_size_left: reduxState.soleSpecification[0].soleData.soleSizeLeft,
                sole_size_right: reduxState.soleSpecification[0].soleData.soleSizeRight,
                cover_material: reduxState.soleSpecification[0].soleData.coverMaterial,
                cover_level: reduxState.soleSpecification[0].soleData.coverLevel,
                total_left_sole_thickness: reduxState.soleSpecification[0].soleData.totalLeftSoleThickness,
                total_right_sole_thickness: reduxState.soleSpecification[0].soleData.totalRightSoleThickness,
                sole_completeness: reduxState.soleSpecification[0].soleData.soleCompleteness,
                hypoallergenic_glue: reduxState.soleSpecification[0].soleData.hypoallergenicGlue,
                asc_pad_left: reduxState.soleResult[0].soleResultData.left.ascPad === 'true',
                fasci_pad_left:reduxState.soleResult[0].soleResultData.left.fasciPad=== 'true',
                asc_pad_right: reduxState.soleResult[0].soleResultData.right.ascPad === 'true',
                fasci_pad_right: reduxState.soleResult[0].soleResultData.right.fasciPad === 'true'
            },
            sole_additions: reduxState.soleAddition.additionData.addition.map(addition => ({
                name: addition.name,
                location: addition.location
            }))
        },
        fits_result: {
            fits_specification: {
                model: reduxState.fitsSpecification[0].fitsData.model.displayValue,
                pattern_name: !reduxState.fitsSpecification[0].fitsData.model.displayValue ? '' : reduxState.fitsSpecification[0].fitsData.model.displayValue.includes('Teenslipper') ? 'Teenmodel' : 'Bandenmodel',
                block: reduxState.fitsSpecification[0].fitsData.block,
                milling_size: reduxState.fitsSpecification[0].fitsData.millingSize,
                upper_material: reduxState.fitsSpecification[0].fitsData.upperMaterial,
                cover_material: reduxState.fitsSpecification[0].fitsData.coverMaterial,
                outsole: reduxState.fitsSpecification[0].fitsData.outsole,
                finishing_shape: reduxState.fitsSpecification[0].fitsData.finishingShape,
                upper_position: reduxState.fitsSpecification[0].fitsData.upperPosition,
                sole_size_left: reduxState.fitsSpecification[0].fitsData.soleSizeLeft,
                sole_size_right: reduxState.fitsSpecification[0].fitsData.soleSizeRight,
                left_sole_width: reduxState.fitsSpecification[0].fitsData.leftSoleWidth,
                left_sole_width_message: reduxState.fitsSpecification[0].fitsData.leftSoleWidthMessage,
                right_sole_width: reduxState.fitsSpecification[0].fitsData.rightSoleWidth,
                right_sole_width_message: reduxState.fitsSpecification[0].fitsData.rightSoleWidthMessage,
                hypoallergenic_glue: reduxState.fitsSpecification[0].fitsData.hypoallergenicGlue,
                extra_element: reduxState.fitsSpecification[0].fitsData.extraElement[0],
                asc_pad_left: reduxState.soleResult[0].soleResultData.left.ascPad === 'true',
                fasci_pad_left:reduxState.soleResult[0].soleResultData.left.fasciPad=== 'true',
                asc_pad_right: reduxState.soleResult[0].soleResultData.right.ascPad === 'true',
                fasci_pad_right: reduxState.soleResult[0].soleResultData.right.fasciPad === 'true'
            }
        }
    };
}
