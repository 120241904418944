import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    container: {
        marginTop: 16,
        marginBottom: 40,
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        background: 'linear-gradient(-20deg, #0094D1 30%, #00B8EE 90%)',
        marginLeft: 'auto',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(5),
        color: '#ffffff',
        fontWeight: 'bold',
        boxShadow: '0 0px 0px 0px rgba(255, 255, 255, 0)',
        '&:hover': {
            boxShadow: '0 0px 0px 0px rgba(255, 255, 255, 0)'
        }
    },
    backButton: {
        color: '#000000'
    }
}))

export default styles
