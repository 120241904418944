export const initialState = {
    diagnosisData: {
        numberOfSoles: '1',
        diagnosis:
        {
            name: ''
        },
        extraDiagnosis: [],
        therapyMessage: '',
        symmetrical: true,
        displayRightStanceDifference: false,
        leftStanceDifference: {},
        rightStanceDifference: {},
        completePair: 'both'
    },
    diagnosisAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    diagnosisErrors: {
        diagnosis: false,
        leftStanceDifference: false,
        message: [],
        rightStanceDifference: false
    }
}
