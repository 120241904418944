import React, { useEffect, useState } from 'react'
import General from '../../tabs/general'
import Diagnose from '../../tabs/diagnosis'
import SolesSpecifications from '../../tabs/soleSpecifications'
import SoleResult from '../../tabs/soleResult'
import SoleAddition from '../../tabs/soleAddition'
import { useSelector } from 'react-redux'
import useStyles from './style'
import TherapistDisplay from '../../components/TherapistDisplay'
import NavigationButtons from '../../components/NavigationButtons'
import ValidationErrorDialog from '../../components/ValidationErrorDialog'
import { useNavigate } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

const InsolePage = () => {
    const navigate = useNavigate()

    const { generalErrors, orderType } = useSelector(state => state.general)
    const { diagnosisData, diagnosisErrors } = useSelector((state) => state.diagnosis)
    const soleSpecificationTabs = useSelector(state => state.soleSpecification)
    const soleResultTab = useSelector(state => state.soleResult)
    const { additionErrors } = useSelector(state => state.soleAddition)
    const other = useSelector(state => state.other)

    const soleSpecificationsMessageError = soleSpecificationTabs.reduce((previousValue, currentValue) => previousValue || currentValue.soleErrors.message, false)
    const soleResultMessageError = soleResultTab.reduce((previousValue, currentValue) => previousValue || currentValue.soleResultErrors.message, false)
    const [generalSnackbar, setGeneralSnackbar] = useState([])
    const [diagnosisSnackbar, setDiagnosisSnackbar] = useState([])
    const [soleSpecSnackbar, setSoleSpecSnackbar] = useState([])
    const [additionalSnackbar, setAdditionalSnackbar] = useState([])

    useEffect(() => {
        if (orderType === '') {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (other.allValid) {
            navigate('/order')
        }
    }, [other.allValid, other.errorDialog])

    /**
     * updates displaySnackbar once values
     * from the store are updated
     */
    useEffect(() => {
        updateSnackbar(setGeneralSnackbar, generalSnackbar, generalErrors.message)
    }, [generalErrors.message])

    /**
     * updates displaySnackbar once values
     * from the store are updated
     */
    useEffect(() => {
        updateSnackbar(setDiagnosisSnackbar, diagnosisSnackbar, diagnosisErrors.message)
    }, [diagnosisErrors.message])

    /**
     * updates displaySnackbar once values
     * from the store are updated
     */
    useEffect(() => {
        updateSnackbar(setSoleSpecSnackbar, soleSpecSnackbar, soleSpecificationsMessageError)
    }, [soleSpecificationsMessageError])

    /**
     * updates displaySnackbar once values
     * from the store are updated
     */
    useEffect(() => {
        updateSnackbar(setSoleSpecSnackbar, soleSpecSnackbar, soleResultMessageError)
    }, [soleResultMessageError])

    /**
     * updates displaySnackbar once values
     * from the store are updated
     */
    useEffect(() => {
        updateSnackbar(setAdditionalSnackbar, additionalSnackbar, additionErrors.message)
    }, [additionErrors.message])

    /**
     * Update snackbar function
     *
     * @param {*} setSnackbarArray - useState snackbar array function
     * @param {*} snackbarArray  - useState snackbar array data
     * @param {*} messages - redux messages array
     */
    function updateSnackbar (setSnackbarArray, snackbarArray, messages) {
        const action = snackbarId => (
            <IconButton onClick={() => { closeSnackbar(snackbarId) }}>
                <Close sx={{ color: '#fff' }} />
            </IconButton>
        )
        // loop throug open errors
        snackbarArray.forEach(error => {
            if (!messages.includes(error.value)) {
                // message is not in the reducer so remove the message from the generalsnackbar
                closeSnackbar(error.key)
                const newArr = snackbarArray.filter(e => e.key !== error.key)
                setSnackbarArray(newArr)
            }
        })

        // loop through new errors
        messages.forEach(message => {
            // if error is not in open errors create a new one.
            if (snackbarArray.find((error) => error.value === message) === undefined) {
                const genArray = snackbarArray
                const id = enqueueSnackbar({
                    message,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    autoHideDuration: null,
                    variant: 'error',
                    preventDuplicate: true,
                    action
                })

                genArray.push({ value: message, key: id })
                setSnackbarArray(genArray)
            }
        })
    }

    /**
     * Show as many sole options as selected. For now it is not needed since by default we will only order one.
     * @return {JSX} - a map of JSX solespecification.
     */
    function renderSoleSpecificationsTabs () {
        const soleSpecificationsTabs = []
        for (let i = 0; i < diagnosisData.numberOfSoles; i++) {
            soleSpecificationsTabs.push(<SolesSpecifications key={i} soleSpecNumber={i} />)
        }
        return soleSpecificationsTabs
    }

    /**
     * Show as many sole options as selected. For now it is not needed since by default we will only order one.
     * @return {JSX} - a map of JSX solespecification.
     */
    function renderSoleResultTabs () {
        const soleResTabs = []
        for (let i = 0; i < diagnosisData.numberOfSoles; i++) {
            soleResTabs.push(<SoleResult key={i} soleResNumber={i} />)
        }
        return soleResTabs
    }

    return (
        <div>
            <TherapistDisplay />
            <General />
            <Diagnose />
            { renderSoleSpecificationsTabs() }
            { renderSoleResultTabs() }
            <SoleAddition />
            <SnackbarProvider />
            <ValidationErrorDialog />
            <NavigationButtons />
        </div>
    )
}
export default withStyles(useStyles)(InsolePage)
