import { createStyles } from '@mui/styles'

/**
 * Generate a linear gradient
 *
 * @param {String} dark - dark color
 * @param {String} light -  light color
 * @return {String} linear gradient style property
 */
function buttonGradient (dark, light) {
    return `linear-gradient(-20deg, ${dark} 30%, ${light} 90%)`
}

const styles = createStyles((theme) => ({
    root: {
        height: '100%'
    },
    loading: {
        margin: 0
    },
    title: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    titleContainer: {
        padding: theme.spacing(8)
    },
    svg: {
        width: 100,
        marginRight: theme.spacing(6)
    },
    ApplicationTitle: {
        color: theme.palette.primary.main
    },
    bottomPageSpacer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(11)
    },
    table: {
        marginBottom: theme.spacing(2)
    },
    buttonBar: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    button: {
        background: buttonGradient('#0094D1', theme.palette.primary.main),
        color: '#ffffff',
        fontWeight: 'bold',
        boxShadow: '0 0px 0px 0px rgba(255, 255, 255, 0)',
        '&:hover': {
            boxShadow: '0 0px 0px 0px rgba(255, 255, 255, 0)'
        }
    },
    buttonEmergency: {
        background: buttonGradient('#303EFF', '#4F5DFF')
    },
    buttonDanger: {
        background: buttonGradient('#FF1717', theme.palette.error.main)
    },
    greenButton: {
        background: buttonGradient(theme.palette.success.main, '#5ACC02')
    },
    buttonBack: {
        background: buttonGradient('#EE7203', '#EE8E37')
    },
    errorPaper: {
        maxWidht: '100%',
        backgroundColor: theme.palette.error.light,
        padding: theme.spacing(2),
        width: '100%',
        borderRadius: 5
    },
    warningPaper: {
        maxWidht: '100%',
        backgroundColor: theme.palette.warning.main,
        padding: theme.spacing(2),
        width: '100%',
        borderRadius: 5
    },
    insoleViewer: {
        width: 800,
        height: 600
    },
    errorTypography: {
        color: theme.palette.error.main,
        textAlign: 'center'
    }
}))

export default styles
