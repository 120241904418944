/**
 * Validate if leftStanceDifference and rightStanceDifference are the same value.
 */
export function validateLeftRightStanceDifference (state) {
    const diagnose = state.diagnosisData

    const message = 'Error 301: Je mag niet dezelfde standsafwijking kiezen voor links en rechts'
    if (diagnose.leftStanceDifference.name === diagnose.rightStanceDifference.name && diagnose.displayRightStanceDifference === true) {
        state.diagnosisErrors.leftStanceDifference = true
        state.diagnosisErrors.rightStanceDifference = true
        state.diagnosisErrors.message.push(message)
    } else {
        state.diagnosisErrors.message = state.diagnosisErrors.message.filter(e => e !== message)
        state.diagnosisErrors.leftStanceDifference = false
        state.diagnosisErrors.rightStanceDifference = false
    }

    state.diagnosisErrors.message = state.diagnosisErrors.message.filter(e => e !== 'I.v.m. aanpassingen van de diagnoselijst en bijbehorende elementen zijn de suggesties onder zoolresultaat eventueel aangepast. Check dus goed of de uiteindelijke zool overeenkomt met jouw verwachtingen.')
    state.diagnosisErrors.message = state.diagnosisErrors.message.filter(e => e !== 'De eerder gekozen diagnose staat niet meer op de huidige diagnoselijst. Vul een nieuwe diagnose in. De suggesties onder zoolresultaat worden o.b.v. deze nieuwe diagnose aangepast')
}

/**
 * Validate the sole import in case of an old import.
 */
export function validateOldSoleImport (state, statusDiagnosis) {
    switch (statusDiagnosis) {
    case 'diagnosisChange':
        state.diagnosisErrors.message.push('I.v.m. aanpassingen van de diagnoselijst en bijbehorende elementen zijn de suggesties onder zoolresultaat eventueel aangepast. Check dus goed of de uiteindelijke zool overeenkomt met jouw verwachtingen.')
        break
    case 'diagnosisGone':
        state.diagnosisErrors.message.push('De eerder gekozen diagnose staat niet meer op de huidige diagnoselijst. Vul een nieuwe diagnose in. De suggesties onder zoolresultaat worden o.b.v. deze nieuwe diagnose aangepast')
        state.diagnosisErrors.diagnosis = true
        break
    }
}

/**
 * Validate fields if not empty, and filled correctly
 *
 * @param {Object} state - current redux state
 */
export function validateAllFields (state) {
    const diagnosisErrors = state.diagnosisErrors
    let validate = true
    if (diagnosisErrors.leftStanceDifference ||
    diagnosisErrors.rightStanceDifference ||
    checkEmptyFields(state)) validate = false
    state.diagnosisAccordion.validate = validate
}

/**
 * Check if the fields of diagnose are empty.
 *
 * @param {Object} state - redux state object
 * @returns {boolean} true
 */
function checkEmptyFields (state) {
    const diagnose = state.diagnosisData

    state.diagnosisErrors.diagnosis = diagnose.diagnosis.name === ''
    state.diagnosisErrors.leftStanceDifference = diagnose.leftStanceDifference.name === undefined

    if (diagnose.displayRightStanceDifference) {
        state.diagnosisErrors.rightStanceDifference = diagnose.rightStanceDifference.name === undefined
    }

    return diagnose.diagnosis.name === '' || diagnose.leftStanceDifference.name === undefined || (diagnose.displayRightStanceDifference ? diagnose.rightStanceDifference.name === undefined : false)
}
