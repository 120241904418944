import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    root: {
        height: '100%'
    },
    loading: {
        margin: 0
    },
    title: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    therapistInput: {
        color: 'black'
    },
    autoComplete: {
        marginTop: 16
    },
    accordionError: {
        backgroundColor: theme.palette.error.light
    },
    accordionSuccess: {
        backgroundColor: theme.palette.success.light
    },
    titleError: {
        fontWeight: 'bold',
        color: theme.palette.error.main
    },
    titleSuccess: {
        fontWeight: 'bold',
        color: theme.palette.success.main
    },
    subtitleWidth: {
        maxWidth: 150,
        minWidth: 150
    },
    titleWidth: {
        maxWidth: 600,
        minWidth: 600
    },
    chip: {
        margin: 'unset',
        height: 'unset'
    }
}))

export default styles
