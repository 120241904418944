import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    root: {
        height: '100%'
    },
    loading: {
        margin: 0
    },
    accordionError: {
        backgroundColor: theme.palette.error.light
    },
    accordionSuccess: {
        backgroundColor: theme.palette.success.light
    },
    titleError: {
        fontWeight: 'bold',
        color: theme.palette.error.main
    },
    titleSuccess: {
        fontWeight: 'bold',
        color: theme.palette.success.main
    },
    errorSnackbar: {
        width: '60%',
        borderRadius: 5
    },
    autoComplete: {
        marginTop: 16
    },
    errorPaper: {
        backgroundColor: theme.palette.error.light,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: '100%',
        borderRadius: 5
    },
    errorTypography: {
        color: theme.palette.error.main,
        textAlign: 'center'
    },
    title: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    buttonGrid: {
        height: '100%'
    },
    fabText: {
        paddingTop: 20,
    }, 
    closeRightsole: {
        marginTop: 20,
        background: 'linear-gradient(-20deg, #0094D1 30%, #00B8EE 90%)',
        color: '#ffffff'
    }
}))

export default styles
